import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SuperAdminService } from '../../../../services/super-admin.service';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../../../services/loading.service';
import { NewCompanyRequest } from '../../../../payloads/requests/newCompanyRequest';
// PrimeNG Modules
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-companies',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    DialogModule,
    ReactiveFormsModule,
    CheckboxModule,
    ScrollPanelModule,
    ToastModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './manage-companies.component.html',
  styleUrl: './manage-companies.component.sass'
})
export class ManageCompaniesComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  myCompanies: any[] = [];
  selectedCompany: any;

  newCompanyDialogIsVisible: boolean = false;

  newCompanyForm = this.formBuilder.group({
    name: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,50}$')])],
    siren: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{9}$')])],
    email: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    phone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    adminEmail: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    adminLastName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    adminFirstName: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    adminPhone: ['', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    legalNoticesAccepted: [false, Validators.requiredTrue]
  });

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private superAdminService: SuperAdminService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.superAdminService.getAllAsSuperAdmin()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myCompanies = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Récupration des partenaires', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      })
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  getLoading() {
    return this.loadingService.get();
  }

  onRowSelectCompany(event: any) {
    this.router.navigate([`portal/super-admin/company-details/${this.selectedCompany.id}`]);
  }
  //#endregion

  //#region COMPONENT DIALOG - NEW COMPANY
  showNewCompanyDialog() {
    this.newCompanyForm.reset();
    this.newCompanyDialogIsVisible = true;
  }

  onSubmitNewCompany() {
    const request: NewCompanyRequest = new NewCompanyRequest(
      this.newCompanyForm.get('name')?.value!,
      this.newCompanyForm.get('siren')?.value!,
      this.newCompanyForm.get('email')?.value!,
      this.newCompanyForm.get('phone')?.value!,
      this.newCompanyForm.get('adminEmail')?.value!,
      this.newCompanyForm.get('adminLastName')?.value!,
      this.newCompanyForm.get('adminFirstName')?.value!,
      this.newCompanyForm.get('phone')?.value!);
    this.superAdminService.create(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.closeNewCompanyDialog();
          this.myCompanies = [...this.myCompanies, response];
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entreprise ajoutée', icon: 'pi pi-times-circle', life: 5000 });
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Créer une entreprise', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      })
  }

  closeNewCompanyDialog() {
    this.newCompanyDialogIsVisible = false;
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
