<!-- Toas module -->
<p-toast></p-toast>
<!-- Toas module -->

<!-- Main structure -->
<div class="block">
    <div>
        <div class="text-3xl font-medium text-900 mb-3 p-2">Mon portail entreprise</div>

        <div class="flex flex-column p-4 surface-card shadow-2 border-round">
            <form [formGroup]="updateMyCompanyDetailsForm" (ngSubmit)="onSubmitUpdateCompany()">
                <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Logo</div>
                        <div class="w-full md:w-8">
                            <div class="flex">

                                <p-fileUpload #fileUpload accept="image/png, image/jpeg" maxFileSize="150000"
                                    previewWidth="100" showCancelButton="false" customUpload="true"
                                    (uploadHandler)="uploadFile($event)">
                                    <ng-template pTemplate="header" let-files let-chooseCallback="chooseCallback"
                                        let-uploadCallback="uploadCallback">
                                        <p-button (onClick)="choose($event, chooseCallback)" icon="pi pi-images"
                                            [rounded]="true" [outlined]="true" />
                                        <p-button (onClick)="uploadEvent(uploadCallback)"
                                            icon="pi pi-cloud-upload" [loading]="getLoading()"
                                            [rounded]="true" [outlined]="true" severity="success"
                                            [disabled]="!files || files.length === 0 || getLoading()" />
                                    </ng-template>
                                </p-fileUpload>

                                <div *ngIf="myCompanyDetails?.logo != null" class="ml-3">
                                    <p-image src="data:image/*;base64,{{ myCompanyDetails.logo }}" alt="Image"
                                        width="100" [preview]="true" />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">SIREN</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ myCompanyDetails?.siren
                            }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Nom</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hideName">{{
                            myCompanyDetails?.name }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hideName">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myCompanyDetails?.name" pInputText
                                    formControlName="name" aria-describedby="name-edit">
                                <small class="text-red-600" id="name-edit"
                                    *ngIf="updateMyCompanyDetailsForm.controls.name.errors?.['pattern']">
                                    Règles: que des caractères alphanumériques, entre 2 et 50 caractères.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="onClickEnableFieldInput('name')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Email</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hideEmail">{{
                            myCompanyDetails?.email }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hideEmail">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myCompanyDetails?.email" pInputText
                                    formControlName="email" aria-describedby="email-edit">
                                <small class="text-red-600" id="email-edit"
                                    *ngIf="updateMyCompanyDetailsForm.controls.email.errors?.['pattern']">
                                    Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="onClickEnableFieldInput('email')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Téléphone</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hidePhone">{{
                            myCompanyDetails?.phone }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hidePhone">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myCompanyDetails?.phone" pInputText
                                    formControlName="phone" aria-describedby="phone-edit">
                                <small class="text-red-600" id="phone-edit"
                                    *ngIf="updateMyCompanyDetailsForm.controls.phone.errors?.['pattern']">
                                    Règles: 10 chiffres.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="onClickEnableFieldInput('phone')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Descritpion</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hideDescription">{{
                            myCompanyDetails?.description }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hideDescription">
                            <div class="flex flex-column">
                                <textarea [placeholder]="myCompanyDetails?.description" pInputTextarea
                                    formControlName="description" aria-describedby="description-edit" maxlength="255"
                                    rows="5" cols="30"></textarea>
                                <small class="text-red-600" id="description-edit"
                                    *ngIf="updateMyCompanyDetailsForm.controls.description.errors?.['pattern']">
                                    Règles: entre 30 et 255 caractères.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" (onClick)="onClickEnableFieldInput('description')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Secteur(s) d'activité(s)</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                            <div class="card flex align-items-center gap-2 flex-wrap">
                                <p-tag severity="success" value="N/A" [rounded]="true" />
                            </div>
                        </div>
                    </li>

                    <div class="w-full flex justify-content-end mt-3">
                        <p-button [rounded]="true" [outlined]="true" type="submit"
                            [disabled]="!updateMyCompanyDetailsForm.valid || getLoading()"
                            icon="pi pi-save" [loading]="getLoading()"
                            label="Mettre à jour" />
                    </div>
                </ul>
            </form>
        </div>
    </div>
</div>
<!-- Main structure -->