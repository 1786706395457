import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoading$$ = new BehaviorSubject<boolean>(false);

  show() {
    this.isLoading$$.next(true);
  }

  hide() {
    this.isLoading$$.next(false);
  }

  get(): boolean {
    return this.isLoading$$.getValue();
  }
}
