import { trigger, transition, style, animate } from '@angular/animations';
import * as i2 from '@angular/common';
import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { PLATFORM_ID, booleanAttribute, Directive, Inject, Input, HostListener, Pipe, forwardRef, EventEmitter, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Output, ViewChild, ContentChildren, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i1 from 'primeng/api';
import { TranslationKeys, PrimeTemplate, SharedModule } from 'primeng/api';
import { DomHandler, ConnectedOverlayScrollHandler } from 'primeng/dom';
import { EyeIcon } from 'primeng/icons/eye';
import { EyeSlashIcon } from 'primeng/icons/eyeslash';
import { TimesIcon } from 'primeng/icons/times';
import * as i3 from 'primeng/inputtext';
import { InputTextModule } from 'primeng/inputtext';
import { ZIndexUtils } from 'primeng/utils';
import * as i4 from 'primeng/autofocus';
import { AutoFocusModule } from 'primeng/autofocus';

/**
 * Password directive.
 * @group Components
 */
const _c0 = ["input"];
const _c1 = (a0, a1) => ({
  showTransitionParams: a0,
  hideTransitionParams: a1
});
const _c2 = a0 => ({
  value: "visible",
  params: a0
});
const _c3 = a0 => ({
  width: a0
});
function Password_ng_container_6_TimesIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "TimesIcon", 10);
    i0.ɵɵlistener("click", function Password_ng_container_6_TimesIcon_1_Template_TimesIcon_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r3 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r3.clear());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-password-clear-icon");
    i0.ɵɵattribute("data-pc-section", "clearIcon");
  }
}
function Password_ng_container_6_3_ng_template_0_Template(rf, ctx) {}
function Password_ng_container_6_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Password_ng_container_6_3_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Password_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Password_ng_container_6_TimesIcon_1_Template, 1, 2, "TimesIcon", 7);
    i0.ɵɵelementStart(2, "span", 8);
    i0.ɵɵlistener("click", function Password_ng_container_6_Template_span_click_2_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.clear());
    });
    i0.ɵɵtemplate(3, Password_ng_container_6_3_Template, 1, 0, null, 9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r3.clearIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵattribute("data-pc-section", "clearIcon");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.clearIconTemplate);
  }
}
function Password_ng_container_7_ng_container_1_EyeSlashIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "EyeSlashIcon", 12);
    i0.ɵɵlistener("click", function Password_ng_container_7_ng_container_1_EyeSlashIcon_1_Template_EyeSlashIcon_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r3 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r3.onMaskToggle());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "hideIcon");
  }
}
function Password_ng_container_7_ng_container_1_span_2_1_ng_template_0_Template(rf, ctx) {}
function Password_ng_container_7_ng_container_1_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Password_ng_container_7_ng_container_1_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Password_ng_container_7_ng_container_1_span_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵlistener("click", function Password_ng_container_7_ng_container_1_span_2_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r6);
      const ctx_r3 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r3.onMaskToggle());
    });
    i0.ɵɵtemplate(1, Password_ng_container_7_ng_container_1_span_2_1_Template, 1, 0, null, 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.hideIconTemplate);
  }
}
function Password_ng_container_7_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Password_ng_container_7_ng_container_1_EyeSlashIcon_1_Template, 1, 1, "EyeSlashIcon", 11)(2, Password_ng_container_7_ng_container_1_span_2_Template, 2, 1, "span", 11);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r3.hideIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r3.hideIconTemplate);
  }
}
function Password_ng_container_7_ng_container_2_EyeIcon_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "EyeIcon", 12);
    i0.ɵɵlistener("click", function Password_ng_container_7_ng_container_2_EyeIcon_1_Template_EyeIcon_click_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r3 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r3.onMaskToggle());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "showIcon");
  }
}
function Password_ng_container_7_ng_container_2_span_2_1_ng_template_0_Template(rf, ctx) {}
function Password_ng_container_7_ng_container_2_span_2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Password_ng_container_7_ng_container_2_span_2_1_ng_template_0_Template, 0, 0, "ng-template");
  }
}
function Password_ng_container_7_ng_container_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵlistener("click", function Password_ng_container_7_ng_container_2_span_2_Template_span_click_0_listener() {
      i0.ɵɵrestoreView(_r8);
      const ctx_r3 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r3.onMaskToggle());
    });
    i0.ɵɵtemplate(1, Password_ng_container_7_ng_container_2_span_2_1_Template, 1, 0, null, 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.showIconTemplate);
  }
}
function Password_ng_container_7_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Password_ng_container_7_ng_container_2_EyeIcon_1_Template, 1, 1, "EyeIcon", 11)(2, Password_ng_container_7_ng_container_2_span_2_Template, 2, 1, "span", 11);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r3.showIconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r3.showIconTemplate);
  }
}
function Password_ng_container_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Password_ng_container_7_ng_container_1_Template, 3, 2, "ng-container", 5)(2, Password_ng_container_7_ng_container_2_Template, 3, 2, "ng-container", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r3.unmasked);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r3.unmasked);
  }
}
function Password_div_8_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Password_div_8_ng_container_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Password_div_8_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Password_div_8_ng_container_3_ng_container_1_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.contentTemplate);
  }
}
function Password_div_8_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵelement(1, "div", 3);
    i0.ɵɵpipe(2, "mapper");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 16);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("data-pc-section", "meter");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind2(2, 6, ctx_r3.meter, ctx_r3.strengthClass))("ngStyle", i0.ɵɵpureFunction1(9, _c3, ctx_r3.meter ? ctx_r3.meter.width : ""));
    i0.ɵɵattribute("data-pc-section", "meterLabel");
    i0.ɵɵadvance(2);
    i0.ɵɵattribute("data-pc-section", "info");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r3.infoText);
  }
}
function Password_div_8_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Password_div_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 13, 1);
    i0.ɵɵlistener("click", function Password_div_8_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onOverlayClick($event));
    })("@overlayAnimation.start", function Password_div_8_Template_div_animation_overlayAnimation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onAnimationStart($event));
    })("@overlayAnimation.done", function Password_div_8_Template_div_animation_overlayAnimation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r9);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onAnimationEnd($event));
    });
    i0.ɵɵtemplate(2, Password_div_8_ng_container_2_Template, 1, 0, "ng-container", 9)(3, Password_div_8_ng_container_3_Template, 2, 1, "ng-container", 14)(4, Password_div_8_ng_template_4_Template, 5, 11, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor)(6, Password_div_8_ng_container_6_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const content_r10 = i0.ɵɵreference(5);
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", "p-password-panel p-component")("@overlayAnimation", i0.ɵɵpureFunction1(10, _c2, i0.ɵɵpureFunction2(7, _c1, ctx_r3.showTransitionOptions, ctx_r3.hideTransitionOptions)));
    i0.ɵɵattribute("data-pc-section", "panel");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.headerTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r3.contentTemplate)("ngIfElse", content_r10);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.footerTemplate);
  }
}
class PasswordDirective {
  document;
  platformId;
  renderer;
  el;
  zone;
  config;
  /**
   * Text to prompt password entry. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  promptLabel = 'Enter a password';
  /**
   * Text for a weak password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  weakLabel = 'Weak';
  /**
   * Text for a medium password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  mediumLabel = 'Medium';
  /**
   * Text for a strong password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  strongLabel = 'Strong';
  /**
   * Whether to show the strength indicator or not.
   * @group Props
   */
  feedback = true;
  /**
   * Sets the visibility of the password field.
   * @group Props
   */
  set showPassword(show) {
    this.el.nativeElement.type = show ? 'text' : 'password';
  }
  /**
   * Specifies the input variant of the component.
   * @group Props
   */
  variant = 'outlined';
  panel;
  meter;
  info;
  filled;
  scrollHandler;
  documentResizeListener;
  constructor(document, platformId, renderer, el, zone, config) {
    this.document = document;
    this.platformId = platformId;
    this.renderer = renderer;
    this.el = el;
    this.zone = zone;
    this.config = config;
  }
  ngDoCheck() {
    this.updateFilledState();
  }
  onInput(e) {
    this.updateFilledState();
  }
  updateFilledState() {
    this.filled = this.el.nativeElement.value && this.el.nativeElement.value.length;
  }
  createPanel() {
    if (isPlatformBrowser(this.platformId)) {
      this.panel = this.renderer.createElement('div');
      this.renderer.addClass(this.panel, 'p-password-panel');
      this.renderer.addClass(this.panel, 'p-component');
      this.renderer.addClass(this.panel, 'p-password-panel-overlay');
      this.renderer.addClass(this.panel, 'p-connected-overlay');
      this.meter = this.renderer.createElement('div');
      this.renderer.addClass(this.meter, 'p-password-meter');
      this.renderer.appendChild(this.panel, this.meter);
      this.info = this.renderer.createElement('div');
      this.renderer.addClass(this.info, 'p-password-info');
      this.renderer.setProperty(this.info, 'textContent', this.promptLabel);
      this.renderer.appendChild(this.panel, this.info);
      this.renderer.setStyle(this.panel, 'minWidth', `${this.el.nativeElement.offsetWidth}px`);
      this.renderer.appendChild(document.body, this.panel);
    }
  }
  showOverlay() {
    if (this.feedback) {
      if (!this.panel) {
        this.createPanel();
      }
      this.renderer.setStyle(this.panel, 'zIndex', String(++DomHandler.zindex));
      this.renderer.setStyle(this.panel, 'display', 'block');
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          DomHandler.addClass(this.panel, 'p-connected-overlay-visible');
          this.bindScrollListener();
          this.bindDocumentResizeListener();
        }, 1);
      });
      DomHandler.absolutePosition(this.panel, this.el.nativeElement);
    }
  }
  hideOverlay() {
    if (this.feedback && this.panel) {
      DomHandler.addClass(this.panel, 'p-connected-overlay-hidden');
      DomHandler.removeClass(this.panel, 'p-connected-overlay-visible');
      this.unbindScrollListener();
      this.unbindDocumentResizeListener();
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.ngOnDestroy();
        }, 150);
      });
    }
  }
  onFocus() {
    this.showOverlay();
  }
  onBlur() {
    this.hideOverlay();
  }
  onKeyup(e) {
    if (this.feedback) {
      let value = e.target.value,
        label = null,
        meterPos = null;
      if (value.length === 0) {
        label = this.promptLabel;
        meterPos = '0px 0px';
      } else {
        var score = this.testStrength(value);
        if (score < 30) {
          label = this.weakLabel;
          meterPos = '0px -10px';
        } else if (score >= 30 && score < 80) {
          label = this.mediumLabel;
          meterPos = '0px -20px';
        } else if (score >= 80) {
          label = this.strongLabel;
          meterPos = '0px -30px';
        }
      }
      if (!this.panel || !DomHandler.hasClass(this.panel, 'p-connected-overlay-visible')) {
        this.showOverlay();
      }
      this.renderer.setStyle(this.meter, 'backgroundPosition', meterPos);
      this.info.textContent = label;
    }
  }
  testStrength(str) {
    let grade = 0;
    let val;
    val = str.match('[0-9]');
    grade += this.normalize(val ? val.length : 1 / 4, 1) * 25;
    val = str.match('[a-zA-Z]');
    grade += this.normalize(val ? val.length : 1 / 2, 3) * 10;
    val = str.match('[!@#$%^&*?_~.,;=]');
    grade += this.normalize(val ? val.length : 1 / 6, 1) * 35;
    val = str.match('[A-Z]');
    grade += this.normalize(val ? val.length : 1 / 6, 1) * 30;
    grade *= str.length / 8;
    return grade > 100 ? 100 : grade;
  }
  normalize(x, y) {
    let diff = x - y;
    if (diff <= 0) return x / y;else return 1 + 0.5 * (x / (x + y / 4));
  }
  get disabled() {
    return this.el.nativeElement.disabled;
  }
  bindScrollListener() {
    if (!this.scrollHandler) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.el.nativeElement, () => {
        if (DomHandler.hasClass(this.panel, 'p-connected-overlay-visible')) {
          this.hideOverlay();
        }
      });
    }
    this.scrollHandler.bindScrollListener();
  }
  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
  bindDocumentResizeListener() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.documentResizeListener) {
        const window = this.document.defaultView;
        this.documentResizeListener = this.renderer.listen(window, 'resize', this.onWindowResize.bind(this));
      }
    }
  }
  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      this.documentResizeListener();
      this.documentResizeListener = null;
    }
  }
  onWindowResize() {
    if (!DomHandler.isTouchDevice()) {
      this.hideOverlay();
    }
  }
  ngOnDestroy() {
    if (this.panel) {
      if (this.scrollHandler) {
        this.scrollHandler.destroy();
        this.scrollHandler = null;
      }
      this.unbindDocumentResizeListener();
      this.renderer.removeChild(this.document.body, this.panel);
      this.panel = null;
      this.meter = null;
      this.info = null;
    }
  }
  static ɵfac = function PasswordDirective_Factory(t) {
    return new (t || PasswordDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i1.PrimeNGConfig));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: PasswordDirective,
    selectors: [["", "pPassword", ""]],
    hostAttrs: [1, "p-inputtext", "p-component", "p-element"],
    hostVars: 4,
    hostBindings: function PasswordDirective_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("input", function PasswordDirective_input_HostBindingHandler($event) {
          return ctx.onInput($event);
        })("focus", function PasswordDirective_focus_HostBindingHandler() {
          return ctx.onFocus();
        })("blur", function PasswordDirective_blur_HostBindingHandler() {
          return ctx.onBlur();
        })("keyup", function PasswordDirective_keyup_HostBindingHandler($event) {
          return ctx.onKeyup($event);
        });
      }
      if (rf & 2) {
        i0.ɵɵclassProp("p-filled", ctx.filled)("p-variant-filled", ctx.variant === "filled" || ctx.config.inputStyle() === "filled");
      }
    },
    inputs: {
      promptLabel: "promptLabel",
      weakLabel: "weakLabel",
      mediumLabel: "mediumLabel",
      strongLabel: "strongLabel",
      feedback: [2, "feedback", "feedback", booleanAttribute],
      showPassword: "showPassword",
      variant: "variant"
    },
    features: [i0.ɵɵInputTransformsFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PasswordDirective, [{
    type: Directive,
    args: [{
      selector: '[pPassword]',
      host: {
        class: 'p-inputtext p-component p-element',
        '[class.p-filled]': 'filled',
        '[class.p-variant-filled]': 'variant === "filled" || config.inputStyle() === "filled"'
      }
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }, {
    type: i1.PrimeNGConfig
  }], {
    promptLabel: [{
      type: Input
    }],
    weakLabel: [{
      type: Input
    }],
    mediumLabel: [{
      type: Input
    }],
    strongLabel: [{
      type: Input
    }],
    feedback: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    showPassword: [{
      type: Input
    }],
    variant: [{
      type: Input
    }],
    onInput: [{
      type: HostListener,
      args: ['input', ['$event']]
    }],
    onFocus: [{
      type: HostListener,
      args: ['focus']
    }],
    onBlur: [{
      type: HostListener,
      args: ['blur']
    }],
    onKeyup: [{
      type: HostListener,
      args: ['keyup', ['$event']]
    }]
  });
})();
class MapperPipe {
  transform(value, mapper, ...args) {
    return mapper(value, ...args);
  }
  static ɵfac = function MapperPipe_Factory(t) {
    return new (t || MapperPipe)();
  };
  static ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
    name: "mapper",
    type: MapperPipe,
    pure: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MapperPipe, [{
    type: Pipe,
    args: [{
      name: 'mapper',
      pure: true
    }]
  }], null, null);
})();
const Password_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Password),
  multi: true
};
/**
 * Password displays strength indicator for password fields.
 * @group Components
 */
class Password {
  document;
  platformId;
  renderer;
  cd;
  config;
  el;
  overlayService;
  /**
   * Defines a string that labels the input for accessibility.
   * @group Props
   */
  ariaLabel;
  /**
   * Specifies one or more IDs in the DOM that labels the input field.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Label of the input for accessibility.
   * @group Props
   */
  label;
  /**
   * Indicates whether the component is disabled or not.
   * @group Props
   */
  disabled;
  /**
   * Text to prompt password entry. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  promptLabel;
  /**
   * Regex value for medium regex.
   * @group Props
   */
  mediumRegex = '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})';
  /**
   * Regex value for strong regex.
   * @group Props
   */
  strongRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';
  /**
   * Text for a weak password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  weakLabel;
  /**
   * Text for a medium password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  mediumLabel;
  /**
   * specifies the maximum number of characters allowed in the input element.
   * @group Props
   */
  maxLength;
  /**
   * Text for a strong password. Defaults to PrimeNG I18N API configuration.
   * @group Props
   */
  strongLabel;
  /**
   * Identifier of the accessible input element.
   * @group Props
   */
  inputId;
  /**
   * Whether to show the strength indicator or not.
   * @group Props
   */
  feedback = true;
  /**
   * Id of the element or "body" for document where the overlay should be appended to.
   * @group Props
   */
  appendTo;
  /**
   * Whether to show an icon to display the password as plain text.
   * @group Props
   */
  toggleMask;
  /**
   * Style class of the input field.
   * @group Props
   */
  inputStyleClass;
  /**
   * Style class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Inline style of the input field.
   * @group Props
   */
  inputStyle;
  /**
   * Transition options of the show animation.
   * @group Props
   */
  showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
  /**
   * Transition options of the hide animation.
   * @group Props
   */
  hideTransitionOptions = '.1s linear';
  /**
   * Specify automated assistance in filling out password by browser.
   * @group Props
   */
  autocomplete;
  /**
   * Advisory information to display on input.
   * @group Props
   */
  placeholder;
  /**
   * When enabled, a clear icon is displayed to clear the value.
   * @group Props
   */
  showClear = false;
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  autofocus;
  /**
   * Specifies the input variant of the component.
   * @group Props
   */
  variant = 'outlined';
  /**
   * Callback to invoke when the component receives focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  /**
   * Callback to invoke when the component loses focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  /**
   * Callback to invoke when clear button is clicked.
   * @group Emits
   */
  onClear = new EventEmitter();
  input;
  contentTemplate;
  footerTemplate;
  headerTemplate;
  clearIconTemplate;
  hideIconTemplate;
  showIconTemplate;
  templates;
  overlayVisible = false;
  meter;
  infoText;
  focused = false;
  unmasked = false;
  mediumCheckRegExp;
  strongCheckRegExp;
  resizeListener;
  scrollHandler;
  overlay;
  value = null;
  onModelChange = () => {};
  onModelTouched = () => {};
  translationSubscription;
  constructor(document, platformId, renderer, cd, config, el, overlayService) {
    this.document = document;
    this.platformId = platformId;
    this.renderer = renderer;
    this.cd = cd;
    this.config = config;
    this.el = el;
    this.overlayService = overlayService;
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;
        case 'header':
          this.headerTemplate = item.template;
          break;
        case 'footer':
          this.footerTemplate = item.template;
          break;
        case 'clearicon':
          this.clearIconTemplate = item.template;
          break;
        case 'hideicon':
          this.hideIconTemplate = item.template;
          break;
        case 'showicon':
          this.showIconTemplate = item.template;
          break;
        default:
          this.contentTemplate = item.template;
          break;
      }
    });
  }
  ngOnInit() {
    this.infoText = this.promptText();
    this.mediumCheckRegExp = new RegExp(this.mediumRegex);
    this.strongCheckRegExp = new RegExp(this.strongRegex);
    this.translationSubscription = this.config.translationObserver.subscribe(() => {
      this.updateUI(this.value || '');
    });
  }
  onAnimationStart(event) {
    switch (event.toState) {
      case 'visible':
        this.overlay = event.element;
        ZIndexUtils.set('overlay', this.overlay, this.config.zIndex.overlay);
        this.appendContainer();
        this.alignOverlay();
        this.bindScrollListener();
        this.bindResizeListener();
        break;
      case 'void':
        this.unbindScrollListener();
        this.unbindResizeListener();
        this.overlay = null;
        break;
    }
  }
  onAnimationEnd(event) {
    switch (event.toState) {
      case 'void':
        ZIndexUtils.clear(event.element);
        break;
    }
  }
  appendContainer() {
    if (this.appendTo) {
      if (this.appendTo === 'body') this.renderer.appendChild(this.document.body, this.overlay);else this.document.getElementById(this.appendTo).appendChild(this.overlay);
    }
  }
  alignOverlay() {
    if (this.appendTo) {
      this.overlay.style.minWidth = DomHandler.getOuterWidth(this.input.nativeElement) + 'px';
      DomHandler.absolutePosition(this.overlay, this.input.nativeElement);
    } else {
      DomHandler.relativePosition(this.overlay, this.input.nativeElement);
    }
  }
  onInput(event) {
    this.value = event.target.value;
    this.onModelChange(this.value);
  }
  onInputFocus(event) {
    this.focused = true;
    if (this.feedback) {
      this.overlayVisible = true;
    }
    this.onFocus.emit(event);
  }
  onInputBlur(event) {
    this.focused = false;
    if (this.feedback) {
      this.overlayVisible = false;
    }
    this.onModelTouched();
    this.onBlur.emit(event);
  }
  onKeyUp(event) {
    if (this.feedback) {
      let value = event.target.value;
      this.updateUI(value);
      if (event.code === 'Escape') {
        this.overlayVisible && (this.overlayVisible = false);
        return;
      }
      if (!this.overlayVisible) {
        this.overlayVisible = true;
      }
    }
  }
  updateUI(value) {
    let label = null;
    let meter = null;
    switch (this.testStrength(value)) {
      case 1:
        label = this.weakText();
        meter = {
          strength: 'weak',
          width: '33.33%'
        };
        break;
      case 2:
        label = this.mediumText();
        meter = {
          strength: 'medium',
          width: '66.66%'
        };
        break;
      case 3:
        label = this.strongText();
        meter = {
          strength: 'strong',
          width: '100%'
        };
        break;
      default:
        label = this.promptText();
        meter = null;
        break;
    }
    this.meter = meter;
    this.infoText = label;
  }
  onMaskToggle() {
    this.unmasked = !this.unmasked;
  }
  onOverlayClick(event) {
    this.overlayService.add({
      originalEvent: event,
      target: this.el.nativeElement
    });
  }
  testStrength(str) {
    let level = 0;
    if (this.strongCheckRegExp.test(str)) level = 3;else if (this.mediumCheckRegExp.test(str)) level = 2;else if (str.length) level = 1;
    return level;
  }
  writeValue(value) {
    if (value === undefined) this.value = null;else this.value = value;
    if (this.feedback) this.updateUI(this.value || '');
    this.cd.markForCheck();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    this.disabled = val;
    this.cd.markForCheck();
  }
  bindScrollListener() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.scrollHandler) {
        this.scrollHandler = new ConnectedOverlayScrollHandler(this.input.nativeElement, () => {
          if (this.overlayVisible) {
            this.overlayVisible = false;
          }
        });
      }
      this.scrollHandler.bindScrollListener();
    }
  }
  bindResizeListener() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.resizeListener) {
        const window = this.document.defaultView;
        this.resizeListener = this.renderer.listen(window, 'resize', () => {
          if (this.overlayVisible && !DomHandler.isTouchDevice()) {
            this.overlayVisible = false;
          }
        });
      }
    }
  }
  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }
  unbindResizeListener() {
    if (this.resizeListener) {
      this.resizeListener();
      this.resizeListener = null;
    }
  }
  containerClass(toggleMask) {
    return {
      'p-password p-component p-inputwrapper': true,
      'p-input-icon-right': toggleMask
    };
  }
  inputFieldClass(disabled) {
    return {
      'p-password-input': true,
      'p-disabled': disabled
    };
  }
  strengthClass(meter) {
    return `p-password-strength ${meter ? meter.strength : ''}`;
  }
  filled() {
    return this.value != null && this.value.toString().length > 0;
  }
  promptText() {
    return this.promptLabel || this.getTranslation(TranslationKeys.PASSWORD_PROMPT);
  }
  weakText() {
    return this.weakLabel || this.getTranslation(TranslationKeys.WEAK);
  }
  mediumText() {
    return this.mediumLabel || this.getTranslation(TranslationKeys.MEDIUM);
  }
  strongText() {
    return this.strongLabel || this.getTranslation(TranslationKeys.STRONG);
  }
  restoreAppend() {
    if (this.overlay && this.appendTo) {
      if (this.appendTo === 'body') this.renderer.removeChild(this.document.body, this.overlay);else this.document.getElementById(this.appendTo).removeChild(this.overlay);
    }
  }
  inputType(unmasked) {
    return unmasked ? 'text' : 'password';
  }
  getTranslation(option) {
    return this.config.getTranslation(option);
  }
  clear() {
    this.value = null;
    this.onModelChange(this.value);
    this.writeValue(this.value);
    this.onClear.emit();
  }
  ngOnDestroy() {
    if (this.overlay) {
      ZIndexUtils.clear(this.overlay);
      this.overlay = null;
    }
    this.restoreAppend();
    this.unbindResizeListener();
    if (this.scrollHandler) {
      this.scrollHandler.destroy();
      this.scrollHandler = null;
    }
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
  }
  static ɵfac = function Password_Factory(t) {
    return new (t || Password)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.PrimeNGConfig), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.OverlayService));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Password,
    selectors: [["p-password"]],
    contentQueries: function Password_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function Password_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.input = _t.first);
      }
    },
    hostAttrs: [1, "p-element", "p-inputwrapper"],
    hostVars: 8,
    hostBindings: function Password_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("p-inputwrapper-filled", ctx.filled())("p-inputwrapper-focus", ctx.focused)("p-password-clearable", ctx.showClear)("p-password-mask", ctx.toggleMask);
      }
    },
    inputs: {
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy",
      label: "label",
      disabled: [2, "disabled", "disabled", booleanAttribute],
      promptLabel: "promptLabel",
      mediumRegex: "mediumRegex",
      strongRegex: "strongRegex",
      weakLabel: "weakLabel",
      mediumLabel: "mediumLabel",
      maxLength: [2, "maxLength", "maxLength", numberAttribute],
      strongLabel: "strongLabel",
      inputId: "inputId",
      feedback: [2, "feedback", "feedback", booleanAttribute],
      appendTo: "appendTo",
      toggleMask: [2, "toggleMask", "toggleMask", booleanAttribute],
      inputStyleClass: "inputStyleClass",
      styleClass: "styleClass",
      style: "style",
      inputStyle: "inputStyle",
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions",
      autocomplete: "autocomplete",
      placeholder: "placeholder",
      showClear: [2, "showClear", "showClear", booleanAttribute],
      autofocus: [2, "autofocus", "autofocus", booleanAttribute],
      variant: "variant"
    },
    outputs: {
      onFocus: "onFocus",
      onBlur: "onBlur",
      onClear: "onClear"
    },
    features: [i0.ɵɵProvidersFeature([Password_VALUE_ACCESSOR]), i0.ɵɵInputTransformsFeature],
    decls: 9,
    vars: 35,
    consts: [["input", ""], ["overlay", ""], ["content", ""], [3, "ngClass", "ngStyle"], ["pInputText", "", "pAutoFocus", "", 3, "input", "focus", "blur", "keyup", "ngClass", "disabled", "ngStyle", "value", "variant", "autofocus"], [4, "ngIf"], [3, "ngClass", "click", 4, "ngIf"], [3, "styleClass", "click", 4, "ngIf"], [1, "p-password-clear-icon", 3, "click"], [4, "ngTemplateOutlet"], [3, "click", "styleClass"], [3, "click", 4, "ngIf"], [3, "click"], [3, "click", "ngClass"], [4, "ngIf", "ngIfElse"], [1, "p-password-meter"], [1, "p-password-info"]],
    template: function Password_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "div", 3);
        i0.ɵɵpipe(1, "mapper");
        i0.ɵɵelementStart(2, "input", 4, 0);
        i0.ɵɵpipe(4, "mapper");
        i0.ɵɵpipe(5, "mapper");
        i0.ɵɵlistener("input", function Password_Template_input_input_2_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onInput($event));
        })("focus", function Password_Template_input_focus_2_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onInputFocus($event));
        })("blur", function Password_Template_input_blur_2_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onInputBlur($event));
        })("keyup", function Password_Template_input_keyup_2_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onKeyUp($event));
        });
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(6, Password_ng_container_6_Template, 4, 3, "ng-container", 5)(7, Password_ng_container_7_Template, 3, 2, "ng-container", 5)(8, Password_div_8_Template, 7, 12, "div", 6);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind2(1, 26, ctx.toggleMask, ctx.containerClass))("ngStyle", ctx.style);
        i0.ɵɵattribute("data-pc-name", "password")("data-pc-section", "root");
        i0.ɵɵadvance(2);
        i0.ɵɵclassMap(ctx.inputStyleClass);
        i0.ɵɵproperty("ngClass", i0.ɵɵpipeBind2(4, 29, ctx.disabled, ctx.inputFieldClass))("disabled", ctx.disabled)("ngStyle", ctx.inputStyle)("value", ctx.value)("variant", ctx.variant)("autofocus", ctx.autofocus);
        i0.ɵɵattribute("label", ctx.label)("aria-label", ctx.ariaLabel)("aria-labelledBy", ctx.ariaLabelledBy)("id", ctx.inputId)("type", i0.ɵɵpipeBind2(5, 32, ctx.unmasked, ctx.inputType))("placeholder", ctx.placeholder)("autocomplete", ctx.autocomplete)("maxlength", ctx.maxLength)("data-pc-section", "input");
        i0.ɵɵadvance(4);
        i0.ɵɵproperty("ngIf", ctx.showClear && ctx.value != null);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.toggleMask);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.overlayVisible);
      }
    },
    dependencies: () => [i2.NgClass, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, i3.InputText, i4.AutoFocus, TimesIcon, EyeSlashIcon, EyeIcon, MapperPipe],
    styles: ["@layer primeng{.p-password{position:relative;display:inline-flex}.p-password-panel{position:absolute;top:0;left:0}.p-password .p-password-panel{min-width:100%}.p-password-meter{height:10px}.p-password-strength{height:100%;width:0%;transition:width 1s ease-in-out}.p-fluid .p-password{display:flex}.p-password-input::-ms-reveal,.p-password-input::-ms-clear{display:none}.p-password-clear-icon{position:absolute;top:50%;margin-top:-.5rem;cursor:pointer}.p-password .p-icon{cursor:pointer}.p-password-clearable.p-password-mask .p-password-clear-icon{margin-top:unset}.p-password-clearable{position:relative}}\n"],
    encapsulation: 2,
    data: {
      animation: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Password, [{
    type: Component,
    args: [{
      selector: 'p-password',
      template: `
        <div [ngClass]="toggleMask | mapper : containerClass" [ngStyle]="style" [class]="styleClass" [attr.data-pc-name]="'password'" [attr.data-pc-section]="'root'">
            <input
                #input
                [attr.label]="label"
                [attr.aria-label]="ariaLabel"
                [attr.aria-labelledBy]="ariaLabelledBy"
                [attr.id]="inputId"
                pInputText
                [ngClass]="disabled | mapper : inputFieldClass"
                [disabled]="disabled"
                [ngStyle]="inputStyle"
                [class]="inputStyleClass"
                [attr.type]="unmasked | mapper : inputType"
                [attr.placeholder]="placeholder"
                [attr.autocomplete]="autocomplete"
                [value]="value"
                [variant]="variant"
                (input)="onInput($event)"
                (focus)="onInputFocus($event)"
                (blur)="onInputBlur($event)"
                (keyup)="onKeyUp($event)"
                [attr.maxlength]="maxLength"
                [attr.data-pc-section]="'input'"
                pAutoFocus
                [autofocus]="autofocus"
            />
            <ng-container *ngIf="showClear && value != null">
                <TimesIcon *ngIf="!clearIconTemplate" [styleClass]="'p-password-clear-icon'" (click)="clear()" [attr.data-pc-section]="'clearIcon'" />
                <span (click)="clear()" class="p-password-clear-icon" [attr.data-pc-section]="'clearIcon'">
                    <ng-template *ngTemplateOutlet="clearIconTemplate"></ng-template>
                </span>
            </ng-container>

            <ng-container *ngIf="toggleMask">
                <ng-container *ngIf="unmasked">
                    <EyeSlashIcon *ngIf="!hideIconTemplate" (click)="onMaskToggle()" [attr.data-pc-section]="'hideIcon'" />
                    <span *ngIf="hideIconTemplate" (click)="onMaskToggle()">
                        <ng-template *ngTemplateOutlet="hideIconTemplate"></ng-template>
                    </span>
                </ng-container>
                <ng-container *ngIf="!unmasked">
                    <EyeIcon *ngIf="!showIconTemplate" (click)="onMaskToggle()" [attr.data-pc-section]="'showIcon'" />
                    <span *ngIf="showIconTemplate" (click)="onMaskToggle()">
                        <ng-template *ngTemplateOutlet="showIconTemplate"></ng-template>
                    </span>
                </ng-container>
            </ng-container>

            <div
                #overlay
                *ngIf="overlayVisible"
                [ngClass]="'p-password-panel p-component'"
                (click)="onOverlayClick($event)"
                [@overlayAnimation]="{ value: 'visible', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }"
                (@overlayAnimation.start)="onAnimationStart($event)"
                (@overlayAnimation.done)="onAnimationEnd($event)"
                [attr.data-pc-section]="'panel'"
            >
                <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                <ng-container *ngIf="contentTemplate; else content">
                    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
                </ng-container>
                <ng-template #content>
                    <div class="p-password-meter" [attr.data-pc-section]="'meter'">
                        <div [ngClass]="meter | mapper : strengthClass" [ngStyle]="{ width: meter ? meter.width : '' }" [attr.data-pc-section]="'meterLabel'"></div>
                    </div>
                    <div class="p-password-info" [attr.data-pc-section]="'info'">{{ infoText }}</div>
                </ng-template>
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
            </div>
        </div>
    `,
      animations: [trigger('overlayAnimation', [transition(':enter', [style({
        opacity: 0,
        transform: 'scaleY(0.8)'
      }), animate('{{showTransitionParams}}')]), transition(':leave', [animate('{{hideTransitionParams}}', style({
        opacity: 0
      }))])])],
      host: {
        class: 'p-element p-inputwrapper',
        '[class.p-inputwrapper-filled]': 'filled()',
        '[class.p-inputwrapper-focus]': 'focused',
        '[class.p-password-clearable]': 'showClear',
        '[class.p-password-mask]': 'toggleMask'
      },
      providers: [Password_VALUE_ACCESSOR],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      styles: ["@layer primeng{.p-password{position:relative;display:inline-flex}.p-password-panel{position:absolute;top:0;left:0}.p-password .p-password-panel{min-width:100%}.p-password-meter{height:10px}.p-password-strength{height:100%;width:0%;transition:width 1s ease-in-out}.p-fluid .p-password{display:flex}.p-password-input::-ms-reveal,.p-password-input::-ms-clear{display:none}.p-password-clear-icon{position:absolute;top:50%;margin-top:-.5rem;cursor:pointer}.p-password .p-icon{cursor:pointer}.p-password-clearable.p-password-mask .p-password-clear-icon{margin-top:unset}.p-password-clearable{position:relative}}\n"]
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i1.PrimeNGConfig
  }, {
    type: i0.ElementRef
  }, {
    type: i1.OverlayService
  }], {
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    promptLabel: [{
      type: Input
    }],
    mediumRegex: [{
      type: Input
    }],
    strongRegex: [{
      type: Input
    }],
    weakLabel: [{
      type: Input
    }],
    mediumLabel: [{
      type: Input
    }],
    maxLength: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    strongLabel: [{
      type: Input
    }],
    inputId: [{
      type: Input
    }],
    feedback: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    appendTo: [{
      type: Input
    }],
    toggleMask: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    inputStyleClass: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    inputStyle: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    autocomplete: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    showClear: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    autofocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    variant: [{
      type: Input
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    onClear: [{
      type: Output
    }],
    input: [{
      type: ViewChild,
      args: ['input']
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class PasswordModule {
  static ɵfac = function PasswordModule_Factory(t) {
    return new (t || PasswordModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: PasswordModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, InputTextModule, AutoFocusModule, TimesIcon, EyeSlashIcon, EyeIcon, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PasswordModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, InputTextModule, AutoFocusModule, TimesIcon, EyeSlashIcon, EyeIcon],
      exports: [PasswordDirective, Password, SharedModule],
      declarations: [PasswordDirective, Password, MapperPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MapperPipe, Password, PasswordDirective, PasswordModule, Password_VALUE_ACCESSOR };
