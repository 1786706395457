import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CommercialService } from '../../../../../services/commercial.service';
import { FormsModule } from '@angular/forms';
import { LoadingService } from '../../../../../services/loading.service';
import { Subject, takeUntil } from 'rxjs';
// PrimeNG Modules
import { ConfirmationService, MessageService } from 'primeng/api';
import { TimelineModule } from 'primeng/timeline';
import { StyleClassModule } from 'primeng/styleclass';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'app-lead-details-commercial',
  standalone: true,
  imports: [
    TimelineModule,
    StyleClassModule,
    CommonModule,
    ToastModule,
    InputTextareaModule,
    ButtonModule,
    DropdownModule,
    ConfirmDialogModule,
    FormsModule,
    AvatarModule
  ],
  providers: [
    MessageService,
    ConfirmationService
  ],
  templateUrl: './lead-details.component.html',
  styleUrl: './lead-details.component.sass'
})
export class LeadDetailsComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  leadDetails: any = {};

  leadMessageEvent: string = '';

  leadId: number;

  allLeadStatus: any[];

  selectedLeadStatus: any;

  @ViewChild('confirmDialogRef') confirmDialogRef!: ConfirmDialog;

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private commercialService: CommercialService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.leadId = +this.route.snapshot.paramMap.get('id')!;
    this.commercialService.getDetails(this.leadId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.leadDetails = response;
          this.commercialService.getAllStatus()
            .pipe(takeUntil(this._destroy$))
            .subscribe({
              next: response2 => {
                this.allLeadStatus = response2;
                this.selectedLeadStatus = this.leadDetails.leadStatus;
              }, error: error => {
                this.messageService.add({ severity: 'error', summary: 'Lister les statuts', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
              }
            });
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister les détails du parrainage', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  getLoading() {
    return this.loadingService.get();
  }

  onClickAddNote() {
    this.commercialService.addNote(this.leadId, this.leadMessageEvent)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.leadMessageEvent = ""
          this.leadDetails.leadEvents = [...this.leadDetails.leadEvents, response];
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Ajouter une note', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }

  confirmUpdateStatus() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      accept: () => {
        this.commercialService.updateStatus(this.leadId, this.selectedLeadStatus.id)
          .pipe(takeUntil(this._destroy$))
          .subscribe({
            next: response => {
              this.leadDetails = response;
              this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Statut mis à jour' });
            }, error: error => {
              this.messageService.add({ severity: 'error', summary: 'Mise à jour du statut', detail: error.description, icon: 'pi pi-info-circle', life: 5000 });
            }
          });
      },
      reject: () => { }
    });
  }

  rejectUpdateStatus() {
    this.confirmDialogRef.reject();
  }

  acceptUpdateStatus() {
    this.confirmDialogRef.accept();
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
