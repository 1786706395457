<!-- Toast module -->
<p-toast></p-toast>
<!-- Toast module -->

<!-- Main structure -->
<div class="block">

    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
        <div class="flex justify-content-between p-2 mb-8">
            <div class="flex">
                <div class="flex flex-column">
                    <div class="relative mx-auto max-w-5rem md:max-w-10rem">
                        <img src="assets/images/web/no_pic.png" class="w-full"
                            *ngIf="leadDetails.company?.logo === null" alt="{{ leadDetails.company?.name }}" />
                        <img src="data:image/*;base64,{{ leadDetails.company?.logo }}" class="w-full border-round"
                            *ngIf="leadDetails.company?.logo != null" />
                    </div>
                    <div class="flex justify-content-center font-bold">
                        {{ leadDetails.company?.name | uppercase}}
                    </div>
                </div>
            </div>
            <div class="flex">
                <div class="border-1 p-2 h-min font-medium">
                    Gains parrainage: N/A
                </div>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5">
            <div class="flex font-bold mb-2">
                PARRAIN
            </div>
            <div class="flex">
                {{ leadDetails.godFather?.firstName }} {{ leadDetails.godFather?.lastName }}
            </div>
            <div class="flex">
                {{ leadDetails.godFather?.phone }}
            </div>
            <div class="flex">
                {{ leadDetails.godFather?.email }}
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5">
            <div class="flex font-bold mb-2">
                FILLEUL
            </div>
            <div class="flex">
                {{ leadDetails.godChild?.firstName }} {{ leadDetails.godChild?.lastName }}
            </div>
            <div class="flex">
                {{ leadDetails.godChild?.phone }}
            </div>
            <div class="flex">
                {{ leadDetails.godChild?.email }}
            </div>
        </div>

        <div class="flex justify-content-between mb-5">
            <div class="flex flex-column p-2">
                <div class="flex font-bold mb-2">
                    COMMERCIAL
                </div>
                <div class="flex">
                    {{ leadDetails.commercial?.firstName }} {{ leadDetails.commercial?.lastName }}
                </div>
                <div class="flex">
                    {{ leadDetails.commercial?.phone }}
                </div>
                <div class="flex">
                    {{ leadDetails.commercial?.email }}
                </div>
            </div>
            <div class="flex flex-column p-2 md:p-5">
                <div class="flex justify-content-end font-bold mb-2">
                    SAV
                </div>
                <div class="flex justify-content-end">
                    {{ leadDetails.company?.phone }}
                </div>
                <div class="flex justify-content-end">
                    {{ leadDetails.company?.email }}
                </div>
            </div>
        </div>

        <div class="p-2">
            <div class="flex justify-content-between border-bottom-1 mb-5 pb-2">
                <span class="flex font-bold">SUIVI</span>
                <span class="flex font-bold"
                    [ngClass]="leadDetails.leadStatus?.label === 'Annulé' ? 'text-red-400' : 'text-green-400'">
                    {{ leadDetails.leadStatus?.label }}
                </span>
            </div>

            <div class="grid mx-5">
                <div [ngClass]="leadDetails.updatable ? 'col-12 md:col-6' : 'col-12'">
                    <p-timeline [value]="leadDetails.leadEvents">
                        <ng-template pTemplate="content" let-event>
                            <small>{{ event.createdBy }}, {{ event.createdAt | date:'medium'}}</small>
                            <p class="p-text-secondary">{{ event.eventMessage }}</p>
                        </ng-template>
                        <ng-template pTemplate="opposite" let-event>
                            {{ event.eventTypeLabel }}
                        </ng-template>
                    </p-timeline>
                </div>
                <div class="col-12 md:col-6" *ngIf="leadDetails.updatable">
                    <div class="flex justify-content-center">
                        <div class="flex flex-column">
                            <span class="p-float-label">
                                <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="leadMessageEvent"
                                    maxlength="100"></textarea>
                                <label for="float-input">Note (100 caractères max).</label>
                            </span>
                            <p-button class="text-right"
                                icon="pi pi-save" [loading]="getLoading()" label="Ajouter"
                                [rounded]="true" [outlined]="true" (onClick)="onClickAddNote()"
                                [disabled]="leadMessageEvent.length === 0 || getLoading()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- Main structure -->