export class ContactFormRequest {

  name: string;
  email: string;
  object: string;
  message: string;

  constructor(name: string, email: string, object: string, message: string) {
    this.name = name;
    this.email = email;
    this.object = object;
    this.message = message;
  }

}