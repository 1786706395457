<!-- Toast module -->
<p-toast></p-toast>
<!-- Toast module -->

<!-- Main structure -->
<div class="block">
    <div>
        <div class="text-3xl font-medium text-900 mb-3 p-2">Mes entreprises</div>

        <div class="text-xl font-medium text-900 mb-3 p-2" *ngIf="myCompanies.length === 0">Aucune société trouvée...</div>

        <p-dataView #dv [value]="myCompanies" layout="grid" *ngIf="myCompanies.length > 0">
            <ng-template pTemplate="grid" let-companies>
                <div class="grid grid-nogutter">
                    <div class="col-12 sm:col-6 md:col-4 lg:col-3 p-2" *ngFor="let company of companies; trackBy:identifyCompany">
                        <div class="flex flex-column p-4 surface-card shadow-2 border-round h-full">
                            <div class="flex justify-content-center surface-200 border-round p-3">
                                <img class="flex max-w-8rem" src="assets/images/web/no_pic.png"
                                    alt="{{ company.companyName }}" *ngIf="company.companyLogo === null" />
                                <img class="flex max-w-8rem" src="data:image/*;base64,{{ company.companyLogo }}"
                                    alt="{{ company.companyName }}" *ngIf="company.companyLogo != null" />
                            </div>
                            <div class="pt-4">
                                <div class="flex flex-row justify-content-between align-items-start gap-2">
                                    <div class="w-full">
                                        <div class="text-lg font-medium text-900 mt-1 mb-5 overflow-hidden text-overflow-ellipsis">
                                            {{ company.companyName }}
                                        </div>
                                        <div class="mb-2 overflow-hidden text-overflow-ellipsis">
                                            <span class="font-medium">Email: </span>
                                            <span>
                                                <a href="mailto:{{ company.email }}">
                                                    {{ company.companyEmail }}
                                                </a>
                                            </span>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-medium">Téléphone: </span>
                                            <span>{{ company.companyPhone }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pt-4">
                                <div class="w-full">
                                    <div class="mb-2 font-medium">Secteur(s) d'activité(s)</div>
                                    <div class="w-full">
                                        <div class="flex align-items-center gap-2 flex-wrap">
                                            <p-tag severity="success" value="N/A" [rounded]="true" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </div>
</div>
<!-- Main structure -->