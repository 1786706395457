import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SuperAdminService } from '../../../../services/super-admin.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-company-details',
  standalone: true,
  imports: [
    CommonModule,
    ToastModule,
    TableModule,
    InputTextModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './company-details.component.html',
  styleUrl: './company-details.component.sass'
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {

  companyDetails: any = {};

  private _destroy$ = new Subject<void>();

  constructor(
    private superAdminService: SuperAdminService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.superAdminService.getDetailsByCompanyId(+this.route.snapshot.paramMap.get('id')!)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.companyDetails = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister les détails de l\'entreprise', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
