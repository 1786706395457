import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { MyProfileComponent } from './components/portal/my-profile/my-profile.component';
import { authCanActivateGuard } from './auth-can-activate.guard';
import { PortalComponent } from './components/portal/portal.component';
import { ManagePortalComponent } from './components/portal/pro/admin/manage-portal/manage-portal.component';
import { ManageEmployeesComponent } from './components/portal/pro/admin/manage-employees/manage-employees.component';
import { CustomerComponent } from './components/portal/customer/customer.component';
import { MyLeadsComponent as MyLeadsCustomer } from './components/portal/customer/my-leads/my-leads.component';
import { MyLeadsComponent as MyLeadsCommercial } from './components/portal/pro/commercial/my-leads/my-leads.component';
import { ProComponent } from './components/portal/pro/pro.component';
import { AdminComponent } from './components/portal/pro/admin/admin.component';
import { CommercialComponent } from './components/portal/pro/commercial/commercial.component';
import { LeadDetailsComponent as LeadDetailsCustomer } from './components/portal/customer/lead-details/lead-details.component';
import { LeadDetailsComponent as LeadDetailsCommercial } from './components/portal/pro/commercial/lead-details/lead-details.component';
import { DashboardComponent } from './components/portal/dashboard/dashboard.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MyCompaniesComponent } from './components/portal/customer/my-companies/my-companies.component';
import { MyCustomersComponent } from './components/portal/pro/commercial/my-customers/my-customers.component';
import { UnavailableComponent } from './components/unavailable/unavailable.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { ManageCustomersComponent } from './components/portal/pro/admin/manage-customers/manage-customers.component';
import { SuperAdminComponent } from './components/portal/super-admin/super-admin.component';
import { ManageCompaniesComponent } from './components/portal/super-admin/manage-companies/manage-companies.component';
import { CompanyDetailsComponent } from './components/portal/super-admin/company-details/company-details.component';

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    title: 'Accueil'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Login',
    canActivate: [authCanActivateGuard]
  },
  {
    path: 'portal',
    component: PortalComponent,
    title: 'Portail',
    canActivate: [authCanActivateGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        title: 'Dashboard',
        canActivate: [authCanActivateGuard]
      },
      {
        path: 'my-profile',
        component: MyProfileComponent,
        title: 'Mon profile',
        canActivate: [authCanActivateGuard]
      },
      {
        path: 'super-admin',
        component: SuperAdminComponent,
        title: 'Super admin',
        canActivate: [authCanActivateGuard],
        children: [
          {
            path: 'company-details/:id',
            component: CompanyDetailsComponent,
            title: 'Détails entreprise'
          },
          {
            path: 'manage-companies',
            component: ManageCompaniesComponent,
            title: 'Partenaires'
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/portal/dashboard'
          }
        ]
      },
      {
        path: 'customer',
        component: CustomerComponent,
        title: 'Client',
        canActivate: [authCanActivateGuard],
        children: [
          {
            path: 'lead-details/:id',
            component: LeadDetailsCustomer,
            title: 'Détails parrainage'
          },
          {
            path: 'my-leads',
            component: MyLeadsCustomer,
            title: 'Mes parrainages'
          },
          {
            path: 'my-companies',
            component: MyCompaniesComponent,
            title: 'Mes entreprises'
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/portal/dashboard'
          }
        ]
      },
      {
        path: 'pro',
        component: ProComponent,
        title: 'Pro',
        canActivate: [authCanActivateGuard],
        children: [
          {
            path: 'admin',
            component: AdminComponent,
            title: 'Admin',
            canActivate: [authCanActivateGuard],
            children: [
              {
                path: 'manage-portal',
                component: ManagePortalComponent,
                title: 'Mon portail entreprise'
              },
              {
                path: 'manage-employees',
                component: ManageEmployeesComponent,
                title: 'Mes employés'
              },
              {
                path: 'manage-customers',
                component: ManageCustomersComponent,
                title: 'Mon portefeuille client'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/portal/dashboard'
              }
            ]
          },
          {
            path: 'commercial',
            component: CommercialComponent,
            title: 'Commercial',
            canActivate: [authCanActivateGuard],
            children: [
              {
                path: 'lead-details/:id',
                component: LeadDetailsCommercial,
                title: 'Détails parrainage'
              },
              {
                path: 'my-leads',
                component: MyLeadsCommercial,
                title: 'Mes parrainages'
              },
              {
                path: 'my-customers',
                component: MyCustomersComponent,
                title: 'Mes clients'
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/portal/dashboard'
              }
            ]
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/portal/dashboard'
          }
        ]
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      }
    ]
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    title: 'Not found'
  },
  {
    path: 'unavailable',
    component: UnavailableComponent,
    title: 'Unavailable'
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    title: 'Forbidden'
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];
