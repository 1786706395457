import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommercialService } from '../../../../../services/commercial.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
// PrimeNG Modules
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'app-my-customers',
  standalone: true,
  imports: [
    DataViewModule,
    TagModule,
    ToastModule,
    AvatarModule,
    CommonModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './my-customers.component.html',
  styleUrl: './my-customers.component.sass'
})
export class MyCustomersComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  myCustomers: any[] = [];
  myProspects: any[] = [];

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private commercialService: CommercialService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.commercialService.getAllCustomers()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myCustomers = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister mes clients', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
    this.commercialService.getAllProspects()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myProspects = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister mes prospects', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  identifyCustomer(index: any, item: any) {
    return item.id;
  }

  identifyProspect(index: any, item: any) {
    return item.id;
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
