import { Component } from '@angular/core';
import { Router } from '@angular/router';
// PrimeNG Modules
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    ButtonModule
  ],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.sass'
})
export class NotFoundComponent {

  constructor(
    private router: Router
  ) {}

  goToHomePage() {
    this.router.navigate(['home']);
  }

}
