import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminService } from '../../../../../services/admin.service';
import { CommonModule } from '@angular/common';
import { NewCustomerRequest } from '../../../../../payloads/requests/newCustomerRequest';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingService } from '../../../../../services/loading.service';
import { CheckCustomerRequest } from '../../../../../payloads/requests/checkCustomerRequest';
// PrimeNG Modules
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-manage-customers',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    ToastModule,
    ReactiveFormsModule,
    DialogModule,
    ScrollPanelModule,
    CheckboxModule,
    DropdownModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './manage-customers.component.html',
  styleUrl: './manage-customers.component.sass'
})
export class ManageCustomersComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  myCustomers: any[] = [];
  myCommercials: any[] = [];

  newCustomerDialogIsVisible: boolean = false;

  newCustomerForm = this.formBuilder.group({
    commercialId: ['', Validators.required],
    email: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    lastName: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    firstName: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    phone: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    legalNoticesAccepted: [false, Validators.requiredTrue]
  });

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private adminService: AdminService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.adminService.getAllCustomers()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myCustomers = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister mes clients', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });

    this.adminService.getAllCommercials()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myCommercials = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Récupration des commerciaux', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      })
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  getLoading() {
    return this.loadingService.get();
  }
  //#endregion

  //#region COMPONENT DIALOG - NEW CUSTOMER
  showNewCustomerDialog() {
    this.newCustomerForm.reset();
    this.newCustomerForm.get('email')?.disable();
    this.newCustomerForm.get('lastName')?.disable();
    this.newCustomerForm.get('firstName')?.disable();
    this.newCustomerForm.get('phone')?.disable();
    this.newCustomerDialogIsVisible = true;
  }

  focusOutCustomerEmail() {
    const emailControl = this.newCustomerForm.get('email')!;
    if (emailControl.value && emailControl.valid) {
      const checkCustomerRequest: CheckCustomerRequest = new CheckCustomerRequest(null, emailControl.value!.toLowerCase());
      this.adminService.checkCustomerFromCustomerCreation(checkCustomerRequest)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: response => {
            if (!Object.values(response).every(x => x === null || x === '')) {
              this.newCustomerForm.get('lastName')?.disable();
              this.newCustomerForm.get('firstName')?.disable();
              this.newCustomerForm.get('phone')?.disable();

              this.newCustomerForm.patchValue({
                lastName: response.lastName,
                firstName: response.firstName,
                phone: response.phone
              });

              this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Utilisateur trouvé. Si la correspondance n\'est pas bonne, vérifiez l\'email.', icon: 'pi pi-info-circle', life: 5000 });
            } else {
              this.newCustomerForm.get('lastName')?.enable();
              this.newCustomerForm.get('firstName')?.enable();
              this.newCustomerForm.get('phone')?.enable();

              this.newCustomerForm.patchValue({
                lastName: '',
                firstName: '',
                phone: ''
              });
            }
          }, error: error => {
            this.newCustomerForm.get('lastName')?.disable();
            this.newCustomerForm.get('firstName')?.disable();
            this.newCustomerForm.get('phone')?.disable();
            this.newCustomerForm.reset();

            if (error.status === 500) {
              this.messageService.add({ severity: 'error', summary: 'Contrôle email filleul', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Contrôle email filleul', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
            }
          }
        })
    }
  }

  onSubmitNewCustomer() {
    const request: NewCustomerRequest = new NewCustomerRequest(
      this.newCustomerForm.get('firstName')?.value!,
      this.newCustomerForm.get('lastName')?.value!,
      this.newCustomerForm.get('email')?.value!,
      this.newCustomerForm.get('phone')?.value!,
      +this.newCustomerForm.get('commercialId')?.value!);
    this.adminService.addCustomer(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.closeNewCustomerDialog();
          this.myCustomers = [...this.myCustomers, response];
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Nouveau client créé' });
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: "Création du client", detail: error.description, icon: 'pi pi-info-circle', life: 5000 });
        }
      });
  }

  onChangeCommercial(event: any) {
    this.newCustomerForm.get('email')?.enable();
  }

  closeNewCustomerDialog() {
    this.newCustomerDialogIsVisible = false;
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
