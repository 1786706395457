import { Component } from '@angular/core';
import { version } from '../../../../package.json';
import { DialogModule } from 'primeng/dialog';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    DialogModule,
    AvatarModule
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.sass'
})
export class FooterComponent {

  version: string = version;

  visibleLegalNotices: boolean = false;
  visibleLicenseAgreement: boolean = false;

  showLegalNotices() {
    this.visibleLegalNotices = true;
  }

  showLicenseAgreement() {
    this.visibleLicenseAgreement = true;
  }

}
