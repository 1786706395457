<!-- Toast module -->
<p-toast></p-toast>
<!-- Toast module -->

<!-- Main structure -->
<div class="block h-screen">
    <div class="flex h-full align-items-center justify-content-center surface-300">
        <div class="surface-100 border-round p-4 xl:w-10 xl:max-w-30rem lg:w-6 md:w-8 w-full mx-5">
            <div class="align-content-center text-center mb-6">
                <span>Bienvenue sur</span>
                <span class="font-bold text-800">&nbsp;Link</span>
                <span class="font-bold text-blue-400">Upp</span>
            </div>
            <div>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()">

                    <label for="username" class="block text-900 font-medium mb-2">Nom d'utilisateur ou
                        email</label>
                    <input id="username" type="text" placeholder="Nom d'utilisateur ou email" pInputText
                        class="w-full mb-3" formControlName="usernameOrEmail">

                    <label for="password" class="block text-900 font-medium mb-2">Mot de passe</label>
                    <p-password id="password" formControlName="password" class="w-full" [feedback]="false"
                        placeholder="Mot de passe" styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                        [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [toggleMask]="true"></p-password>

                    <div class="flex mt-4 justify-content-center">
                        <p-button icon="pi pi-sign-in" [loading]="getLoading()" label="Se connecter" [rounded]="true"
                            [outlined]="true" type="submit" [disabled]="!loginForm.valid || getLoading()" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- Main structure -->