import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomerService } from '../../../../services/customer.service';
import { LoadingService } from '../../../../services/loading.service';
import { Subject, takeUntil } from 'rxjs';
// PrimeNG Modules
import { MessageService } from 'primeng/api';
import { StyleClassModule } from 'primeng/styleclass';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'app-lead-details-customer',
  standalone: true,
  imports: [
    TimelineModule,
    StyleClassModule,
    CommonModule,
    ToastModule,
    InputTextareaModule,
    ButtonModule,
    FormsModule,
    AvatarModule,
  ],
  providers: [
    MessageService
  ],
  templateUrl: './lead-details.component.html',
  styleUrl: './lead-details.component.sass'
})
export class LeadDetailsComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  leadDetails: any = {};

  leadMessageEvent: string = '';

  leadId: number;

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.leadId = +this.route.snapshot.paramMap.get('id')!;
    this.customerService.getDetails(this.leadId)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.leadDetails = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister les détails du parrainage', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  getLoading() {
    return this.loadingService.get();
  }

  onClickAddNote() {
    this.customerService.addNote(this.leadId, this.leadMessageEvent)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.leadMessageEvent = "";
          this.leadDetails.leadEvents = [...this.leadDetails.leadEvents, response];
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Ajoute une note', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
