import { Component, OnDestroy, OnInit } from '@angular/core';
import { JwtService } from '../../../services/jwt.service';
import { CommonModule } from '@angular/common';
import { AdminService } from '../../../services/admin.service';
import { CommercialService } from '../../../services/commercial.service';
import { CustomerService } from '../../../services/customer.service';
import { Subject, takeUntil } from 'rxjs';
// PrimeNG Modules
import { MessageService } from 'primeng/api';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    ImageModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.sass'
})
export class DashboardComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;
  isCommercial: boolean = false;
  isCustomer: boolean = false;

  superAdminDashboard: any = [];
  adminDashboard: any = [];
  commercialDashboard: any = [];
  customerDashboard: any = [];

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private jwtService: JwtService,
    private messageService: MessageService,
    private adminService: AdminService,
    private commercialService: CommercialService,
    private customerService: CustomerService
  ) {}

  ngOnInit() {
    const authenticatedUserRoles: string = this.getAuthenticatedUserRoles();
    if(this.jwtService.isSuperAdmin) {
      this.isSuperAdmin = true;
    }
    if(this.jwtService.isAdmin) {
      this.isAdmin = true;
      this.adminService.getDashboardMetrics()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.adminDashboard = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Dashboard admin', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      })
    }
    if(this.jwtService.isCommercial) {
      this.isCommercial = true;
      this.commercialService.getDashboardMetrics()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.commercialDashboard = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Dashboard commercial', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      })
    }
    if(this.jwtService.isCustomer) {
      this.isCustomer = true;
      this.customerService.getDashboardMetrics()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.customerDashboard = response;
        }
      })
    }
  }

  //#endregion

  //#region COMPONENT FUNCTIONS
  getAuthenticatedUserRoles(): string {
    if (this.jwtService.isValidAuthentication()) {
      return this.jwtService.getRoles();
    }
    return '';
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
