import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginRequest } from '../payloads/requests/loginRequest';
import { environment } from '../../environments/environment';

const API = `${environment.rootApiUrl}/api/auth`;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient
  ) { }

  login(request: LoginRequest): Observable<any> {
    return this.http.post(`${API}/login`, request);
  }

  logout(jwtToken: string): Observable<any> {
    return this.http.post(`${API}/logout`, jwtToken);
  }

}
