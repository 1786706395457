import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { CommercialService } from '../../../../../services/commercial.service';
// PrimeNG Modules
import { TableModule } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-my-leads-commercial',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    InputTextModule,
    TagModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './my-leads.component.html',
  styleUrl: './my-leads.component.sass'
})
export class MyLeadsComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  myLeads: any[] = [];
  selectedLead: any;

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private commercialService: CommercialService,
    private router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.commercialService.getAllAsCommercial()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myLeads = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister mes parrainages', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  onRowSelectLead(event: any) {
    this.router.navigate([`portal/pro/commercial/lead-details/${this.selectedLead.leadId}`]);
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
