<p-toast />

<!-- Main structure -->
<div class="block">

    <div class="flex flex-column p-2 md:p-4 surface-card shadow-2 border-round">
        <div class="flex justify-content-between p-2 mb-8">
            <div class="flex">
                <div class="flex flex-column">
                    <div class="relative mx-auto max-w-5rem md:max-w-10rem">
                        <img src="assets/images/web/no_pic.png" class="w-full" *ngIf="companyDetails.logo === null"
                            alt="{{ companyDetails.name }}" />
                        <img src="data:image/*;base64,{{ companyDetails.logo }}" class="w-full"
                            *ngIf="companyDetails.logo != null" alt="{{ companyDetails.name }}" />
                    </div>
                    <div class="flex justify-content-center font-bold">
                        {{ companyDetails.name | uppercase}}
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5">
            <div class="flex font-bold mb-2">
                SIREN
            </div>
            <div class="flex">
                {{ companyDetails.siren }}
            </div>
        </div>

        <div class="flex justify-content-between mb-5">
            <div class="flex flex-column p-2">
                <div class="flex font-bold mb-2">
                    COORDONNÉES
                </div>
                <div class="flex">
                    {{ companyDetails.email }}
                </div>
                <div class="flex">
                    {{ companyDetails.phone }}
                </div>
            </div>
            <div class="flex flex-column p-2 md:p-5">
                <div class="flex justify-content-end font-bold mb-2">
                    ADMINISTRATEUR
                </div>
                <div class="flex justify-content-end">
                    {{ companyDetails.adminFullName }}
                </div>
                <div class="flex justify-content-end">
                    {{ companyDetails.adminEmail }}
                </div>
                <div class="flex justify-content-end">
                    {{ companyDetails.adminPhone }}
                </div>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5">
            <div class="flex font-bold mb-2">
                DESCRIPTION
            </div>
            <div class="flex">
                {{ companyDetails.description }}
            </div>
        </div>

        <div class="flex justify-content-between mb-5">
            <div class="flex flex-column p-2">
                <div class="flex font-bold mb-2">
                    PARRAINAGES LINKUPP
                </div>
                <div class="flex">
                    <span class="font-medium">
                        Tous:&nbsp;
                    </span>
                    {{ companyDetails.leadsNumber }}
                </div>
                <div class="flex">
                    <span class="font-medium">
                        En cours:&nbsp;
                    </span>
                    {{ companyDetails.runningLeadsNumber }}
                </div>
                <div class="flex">
                    <span class="font-medium">
                        Validé(s):&nbsp;
                    </span>
                    {{ companyDetails.completedLeadsNumber }}
                </div>
                <div class="flex">
                    <span class="font-medium">
                        Annulé(s):&nbsp;
                    </span>
                    {{ companyDetails.canceledLeadsNumber }}
                </div>
            </div>
        </div>

        <div class="flex flex-column p-2 mb-5">
            <div class="flex font-bold mb-2">
                EMPLOYÉS ({{ companyDetails.employees?.length }})
            </div>

            <div class="flex justify-content-between mb-3 p-2" *ngIf="companyDetails.employees?.length === 0">
                <div class="text-xl font-medium text-900">Aucun employé trouvé...</div>
            </div>

            <p-table #dt1 [value]="companyDetails.employees" sortField="firstName" [sortOrder]="1"
                [globalFilterFields]="['firstName', 'lastName', 'email', 'phone']" [tableStyle]="{'min-width': '1rem'}"
                responsiveLayout="stack" [breakpoint]="'767px'" [paginator]="true" [rows]="5"
                [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="companyDetails.employees?.length > 0">

                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end gap-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text"
                                (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                                placeholder="Chercher..." />
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="firstName">Prénom<p-sortIcon field="firstName" /></th>
                        <th pSortableColumn="lastName">Nom<p-sortIcon field="lastName" /></th>
                        <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                        <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-employee>
                    <tr>
                        <td>
                            <span class="md:hidden font-medium">Prénom:&nbsp;</span>
                            <span>{{ employee.firstName }}</span>
                        </td>
                        <td>
                            <span class="md:hidden font-medium">Nom:&nbsp;</span>
                            <span>{{ employee.lastName }}</span>
                        </td>
                        <td>
                            <span class="md:hidden font-medium">Email:&nbsp;</span>
                            <span>{{ employee.email }}</span>
                        </td>
                        <td>
                            <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                            <span>{{ employee.phone }}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="flex flex-column p-2 mb-5">
            <div class="flex font-bold mb-2">
                CLIENTS ({{ companyDetails.customers?.length }})
            </div>

            <div class="flex justify-content-between mb-3 p-2" *ngIf="companyDetails.customers?.length === 0">
                <div class="text-xl font-medium text-900">Aucun client trouvé...</div>
            </div>

            <p-table #dt2 [value]="companyDetails.customers" sortField="firstName" [sortOrder]="1"
                [globalFilterFields]="['firstName', 'lastName', 'email', 'phone']" [tableStyle]="{'min-width': '1rem'}"
                responsiveLayout="stack" [breakpoint]="'767px'" [paginator]="true" [rows]="5"
                [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="companyDetails.customers?.length > 0">

                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end gap-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text"
                                (input)="dt2.filterGlobal($any($event.target).value, 'contains')"
                                placeholder="Chercher..." />
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="firstName">Prénom<p-sortIcon field="firstName" /></th>
                        <th pSortableColumn="lastName">Nom<p-sortIcon field="lastName" /></th>
                        <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                        <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>
                        <td>
                            <span class="md:hidden font-medium">Prénom:&nbsp;</span>
                            <span>{{ customer.firstName }}</span>
                        </td>
                        <td>
                            <span class="md:hidden font-medium">Nom:&nbsp;</span>
                            <span>{{ customer.lastName }}</span>
                        </td>
                        <td>
                            <span class="md:hidden font-medium">Email:&nbsp;</span>
                            <span>{{ customer.email }}</span>
                        </td>
                        <td>
                            <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                            <span>{{ customer.phone }}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="flex flex-column p-2 mb-5">
            <div class="flex font-bold mb-2">
                PROSPECTS ({{ companyDetails.prospects?.length }})
            </div>

            <div class="flex justify-content-between mb-3 p-2" *ngIf="companyDetails.prospects?.length === 0">
                <div class="text-xl font-medium text-900">Aucun prospect trouvé...</div>
            </div>

            <p-table #dt3 [value]="companyDetails.prospects" sortField="firstName" [sortOrder]="1"
                [globalFilterFields]="['firstName', 'lastName', 'email', 'phone']" [tableStyle]="{'min-width': '1rem'}"
                responsiveLayout="stack" [breakpoint]="'767px'" [paginator]="true" [rows]="5"
                [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="companyDetails.prospects?.length > 0">

                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-end gap-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text"
                                (input)="dt3.filterGlobal($any($event.target).value, 'contains')"
                                placeholder="Chercher..." />
                        </span>
                    </div>
                </ng-template>

                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="firstName">Prénom<p-sortIcon field="firstName" /></th>
                        <th pSortableColumn="lastName">Nom<p-sortIcon field="lastName" /></th>
                        <th pSortableColumn="email">Email<p-sortIcon field="email" /></th>
                        <th pSortableColumn="phone">Téléphone<p-sortIcon field="phone" /></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-prospect>
                    <tr>
                        <td>
                            <span class="md:hidden font-medium">Prénom:&nbsp;</span>
                            <span>{{ prospect.firstName }}</span>
                        </td>
                        <td>
                            <span class="md:hidden font-medium">Nom:&nbsp;</span>
                            <span>{{ prospect.lastName }}</span>
                        </td>
                        <td>
                            <span class="md:hidden font-medium">Email:&nbsp;</span>
                            <span>{{ prospect.email }}</span>
                        </td>
                        <td>
                            <span class="md:hidden font-medium">Téléphone:&nbsp;</span>
                            <span>{{ prospect.phone }}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>