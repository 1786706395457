import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NewEmployeeRequest } from '../../../../../payloads/requests/newEmployeeRequest';
import { AdminService } from '../../../../../services/admin.service';
import { LoadingService } from '../../../../../services/loading.service';
import { Subject, takeUntil } from 'rxjs';
// PrimeNG modules
import { MessageService } from 'primeng/api';
import { StyleClassModule } from 'primeng/styleclass';
import { ButtonModule } from 'primeng/button';
import { TableModule, TableRowSelectEvent } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'app-manage-employees',
  standalone: true,
  imports: [
    StyleClassModule,
    CommonModule,
    ButtonModule,
    TableModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    ToastModule,
    TooltipModule,
    InputIconModule,
    IconFieldModule,
    FormsModule,
    ScrollPanelModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './manage-employees.component.html',
  styleUrl: './manage-employees.component.sass'
})
export class ManageEmployeesComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  myCompanyEmployees: any[] = [];

  newEmployeeDialogIsVisible: boolean = false;
  employeeDetailsDialogIsVisible: boolean = false;

  selectedEmployee: any;
  selectedEmployeeIsAdmin: boolean = false;
  selectedEmployeeIsCommercial: boolean = false;

  newEmployeeForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    lastName: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    firstName: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    phone: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    isAdmin: false,
    isCommercial: false,
    legealNoticesAccepted: [false, Validators.requiredTrue]
  });

  legealNoticesAccepted: boolean = false;

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private adminService: AdminService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.adminService.getAllEmployees()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myCompanyEmployees = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister mes employés', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  getLoading() {
    return this.loadingService.get();
  }
  //#endregion

  //#region COMPONENT DIALOG - NEW EMPLOYEE
  showNewEmployeeDialog() {
    this.newEmployeeForm.reset();
    this.newEmployeeForm.get('lastName')?.disable();
    this.newEmployeeForm.get('firstName')?.disable();
    this.newEmployeeForm.get('phone')?.disable();
    this.newEmployeeDialogIsVisible = true;
  }

  atLeastOneRole(): boolean {
    const isAdmin: boolean = this.newEmployeeForm.get('isAdmin')?.value!;
    const isCommercial: boolean = this.newEmployeeForm.get('isCommercial')?.value!;

    return isAdmin || isCommercial ? true : false;
  }

  focusOutEmployeeEmail() {
    const emailControl = this.newEmployeeForm.get('email')!;
    if (emailControl.value && emailControl.valid) {
      this.adminService.checkEmployee(emailControl.value!.toLowerCase())
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: response => {
            this.newEmployeeForm.get('lastName')?.enable();
            this.newEmployeeForm.get('firstName')?.enable();
            this.newEmployeeForm.get('phone')?.enable();
          }, error: error => {
            this.newEmployeeForm.get('lastName')?.disable();
            this.newEmployeeForm.get('firstName')?.disable();
            this.newEmployeeForm.get('phone')?.disable();
            this.newEmployeeForm.reset();

            if (error.status === 500) {
              this.messageService.add({ severity: 'error', summary: 'Contrôle email filleul', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Contrôle email filleul', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
            }
          }
        })
    }
  }

  onSubmitNewEmployee() {
    const request: NewEmployeeRequest = new NewEmployeeRequest(
      this.newEmployeeForm.get('lastName')?.value!,
      this.newEmployeeForm.get('firstName')?.value!,
      this.newEmployeeForm.get('email')?.value!,
      this.newEmployeeForm.get('phone')?.value!,
      this.newEmployeeForm.get('isAdmin')?.value!,
      this.newEmployeeForm.get('isCommercial')?.value!);
    this.adminService.addEmployee(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.closeNewEmployeeDialog();
          this.myCompanyEmployees = [...this.myCompanyEmployees, response];
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Nouvel employé créé' });
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: "Création de l'employé", detail: error.description, icon: 'pi pi-info-circle', life: 5000 });
        }
      });
  }

  closeNewEmployeeDialog() {
    this.newEmployeeDialogIsVisible = false;
  }
  //#endregion

  //#region COMPONENT DIALOG - EMPLOYEE DETAILS
  onRowSelectEmployee(event: TableRowSelectEvent) {
    this.showEmployeeDetailsDialog();
  }

  showEmployeeDetailsDialog() {
    this.employeeDetailsDialogIsVisible = true;

    this.selectedEmployee.roles.forEach((role: any) => {
      if (role.name === 'ROLE_COMPANY_ADMIN') {
        this.selectedEmployeeIsAdmin = true;
      } else if (role.name === 'ROLE_COMPANY_COMMERCIAL') {
        this.selectedEmployeeIsCommercial = true;
      }
    });
  }

  disableIsAdminCheckbox() {
    if (!this.selectedEmployeeIsCommercial) {
      return true;
    }

    return false;
  }

  disableIsCommercialCheckbox() {
    if (!this.selectedEmployeeIsAdmin) {
      return true;
    }

    return false;
  }

  onClickUpdateEmployeeRoles() {
    this.adminService.updateRoles(this.selectedEmployee.id, this.selectedEmployeeIsAdmin, this.selectedEmployeeIsCommercial)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          const itemIndex = this.myCompanyEmployees.findIndex(item => item.id === this.selectedEmployee.id);
          this.myCompanyEmployees[itemIndex] = response;
          this.myCompanyEmployees = Object.assign([], this.myCompanyEmployees);

          this.closeEmployeeDetailsDialog();

          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Rôles mis à jour' });
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Mise à jour des rôles', detail: error.description, icon: 'pi pi-info-circle', life: 5000 });
        }
      });
  }

  closeEmployeeDetailsDialog() {
    this.employeeDetailsDialogIsVisible = false;
    this.selectedEmployeeIsAdmin = false;
    this.selectedEmployeeIsCommercial = false;
  }

  identifyRole(index: any, item: any) {
    return item.id
  }
  //#endregion

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
