import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomerService } from '../../../../services/customer.service';
import { NewLeadRequest } from '../../../../payloads/requests/newLeadRequest';
import { LoadingService } from '../../../../services/loading.service';
import { Subject, takeUntil } from 'rxjs';
import { CheckCustomerRequest } from '../../../../payloads/requests/checkCustomerRequest';
// PrimeNG Modules
import { MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { TagModule } from 'primeng/tag';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'app-my-leads-customer',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    DialogModule,
    DropdownModule,
    ReactiveFormsModule,
    InputTextModule,
    CheckboxModule,
    ToastModule,
    TagModule,
    ScrollPanelModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './my-leads.component.html',
  styleUrl: './my-leads.component.sass'
})
export class MyLeadsComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  myCompanies: any[];
  commercialFullName: string = "";

  myLeads: any[] = [];

  selectedLead: any;

  newLeadDialogIsVisible: boolean = false;

  newLeadForm = this.formBuilder.group({
    company: ['', Validators.required],
    godChildEmail: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')])],
    godChildLastName: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    godChildFirstName: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')])],
    godChildPhone: [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])],
    legalNoticesAccepted: [false, Validators.requiredTrue]
  });

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private router: Router,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.customerService.getAllAsCustomer()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myLeads = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister mes parrainages', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });

    this.customerService.getAllCompanies(true)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myCompanies = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister mes entreprises & commerciaux', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  getLoading() {
    return this.loadingService.get();
  }

  onRowSelectLead(event: any) {
    this.router.navigate([`portal/customer/lead-details/${this.selectedLead.leadId}`]);
  }
  //#endregion

  //#region COMPONENT DIALOG - NEW LEAD
  showNewLeadDialog() {
    this.newLeadForm.reset();
    this.newLeadForm.get('godChildEmail')?.disable();
    this.newLeadForm.get('godChildLastName')?.disable();
    this.newLeadForm.get('godChildFirstName')?.disable();
    this.newLeadForm.get('godChildPhone')?.disable();
    this.commercialFullName = "";
    this.newLeadDialogIsVisible = true;
  }

  focusOutGodChildEmail() {
    const godChildEmailControl = this.newLeadForm.get('godChildEmail')!;
    if (godChildEmailControl.value && godChildEmailControl.valid) {
      const company: any = this.newLeadForm.get('company')?.value;
      const checkCustomerRequest: CheckCustomerRequest = new CheckCustomerRequest(+company.companyId, godChildEmailControl.value!.toLowerCase());
      this.customerService.checkCustomerFromLeadCreation(checkCustomerRequest)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: response => {
            if (!Object.values(response).every(x => x === null || x === '')) {
              this.newLeadForm.get('godChildLastName')?.disable();
              this.newLeadForm.get('godChildFirstName')?.disable();
              this.newLeadForm.get('godChildPhone')?.disable();

              this.newLeadForm.patchValue({
                godChildLastName: response.lastName,
                godChildFirstName: response.firstName,
                godChildPhone: response.phone
              });

              this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Utilisateur trouvé. Si la correspondance n\'est pas bonne, vérifiez l\'email.', icon: 'pi pi-info-circle', life: 5000 });
            } else {
              this.newLeadForm.get('godChildLastName')?.enable();
              this.newLeadForm.get('godChildFirstName')?.enable();
              this.newLeadForm.get('godChildPhone')?.enable();

              this.newLeadForm.patchValue({
                godChildLastName: '',
                godChildFirstName: '',
                godChildPhone: ''
              });
            }
          }, error: error => {
            this.newLeadForm.get('godChildLastName')?.disable();
            this.newLeadForm.get('godChildFirstName')?.disable();
            this.newLeadForm.get('godChildPhone')?.disable();
            this.newLeadForm.reset();

            if (error.status === 500) {
              this.messageService.add({ severity: 'error', summary: 'Contrôle email filleul', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Contrôle email filleul', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
            }
          }
        });
    }
  }

  onChangeCompany(event: any) {
    this.newLeadForm.get('godChildEmail')?.enable();
    this.commercialFullName = event.value.commercial.firstName + ' ' + event.value.commercial.lastName;
  }

  onSubmitNewLead() {
    const company: any = this.newLeadForm.get('company')?.value;
    const request: NewLeadRequest = new NewLeadRequest(
      +company.companyId,
      this.newLeadForm.get('godChildFirstName')?.value!,
      this.newLeadForm.get('godChildLastName')?.value!,
      this.newLeadForm.get('godChildEmail')?.value!,
      this.newLeadForm.get('godChildPhone')?.value!);
    this.customerService.create(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.router.navigate([`portal/customer/lead-details/${response}`]);
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Création du parrainage', detail: error.description, icon: 'pi pi-info-circle', life: 5000 });
        }
      });
  }

  closeNewLeadDialog() {
    this.newLeadDialogIsVisible = false;
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
