<!-- Toast module -->
<p-toast></p-toast>
<!-- Toast module -->

<!-- Main structure -->
<div class="flex">

    <!-- Siebar -->
    <div class="hidden xl:flex flex-column surface-300 h-screen" style="position: sticky; top: 0;">
        <!-- Siebar header -->
        <div class="flex justify-content-between p-2">
            <div class="flex">
                <p-button icon="pi pi-home" [rounded]="true" [text]="true" (onClick)="goToHomePage()" />
            </div>
            <div class="flex">
                <p-button icon="pi pi-sun" [rounded]="true" [text]="true" (onClick)="changeTheme('saga-blue')" [hidden]="theme === 'saga-blue'" />
                <p-button icon="pi pi-moon" [rounded]="true" [text]="true" (onClick)="changeTheme('arya-blue')" [hidden]="theme === 'arya-blue'" />
            </div>
        </div>
        <!-- Siebar header -->

        <!-- Sidebar menu -->
        <div>
            <div class="flex align-items-center justify-content-between px-4 pt-3">
                <span class="font-semibold text-2xl text-primary">Mon portail</span>
            </div>
            <ul class="list-none p-3 m-0">
                <li *ngIf="isAdmin || isCommercial || isCustomer || isSuperAdmin">
                    <a [routerLink]="['dashboard']"
                        class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100">
                        <span class="font-medium">Dashboard</span>
                    </a>
                </li>
                <li *ngIf="isSuperAdmin">
                    <div pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                        leaveToClass="hidden" leaveActiveClass="slideup"
                        class="p-3 flex align-items-center justify-content-between cursor-pointer text-700 hover:surface-100">
                        <span class="font-medium">Administration</span>
                        <i class="pi pi-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 hidden overflow-hidden">
                        <li>
                            <a [routerLink]="['super-admin/manage-companies']"
                                class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                <span class="text-sm">Partenaires</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="isAdmin">
                    <div pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                        leaveToClass="hidden" leaveActiveClass="slideup"
                        class="p-3 flex align-items-center justify-content-between cursor-pointer text-700 hover:surface-100">
                        <span class="font-medium">Administration</span>
                        <i class="pi pi-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 hidden overflow-hidden">
                        <li>
                            <a [routerLink]="['pro/admin/manage-portal']"
                                class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                <span class="text-sm">Mon portail entreprise</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['pro/admin/manage-employees']"
                                class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                <span class="text-sm">Mes employés</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['pro/admin/manage-customers']"
                                class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                <span class="text-sm">Mon portefeuille client</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="isCommercial">
                    <div pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                        leaveToClass="hidden" leaveActiveClass="slideup"
                        class="p-3 flex align-items-center justify-content-between cursor-pointer text-700 hover:surface-100">
                        <span class="font-medium">Commercial</span>
                        <i class="pi pi-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 hidden overflow-hidden">
                        <li>
                            <a [routerLink]="['pro/commercial/my-customers']"
                                class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                <span class="text-sm">Mes clients</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['pro/commercial/my-leads']"
                                class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                <span class="text-sm">Mes parrainages</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="isCustomer">
                    <div pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                        leaveToClass="hidden" leaveActiveClass="slideup"
                        class="p-3 flex align-items-center justify-content-between cursor-pointer text-700 hover:surface-100">
                        <span class="font-medium">Client</span>
                        <i class="pi pi-chevron-down"></i>
                    </div>
                    <ul class="list-none p-0 m-0 hidden overflow-hidden">
                        <li>
                            <a [routerLink]="['customer/my-leads']"
                                class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                <span class="text-sm">Mes parrainages</span>
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['customer/my-companies']"
                                class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                <span class="text-sm">Mes entreprises</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <!-- Sidebar menu -->

        <!-- Sidebar footer -->
        <div class="flex align-content-end flex-wrap h-full">
            <div class="mt-auto" *ngIf="isUser">
                <div class="flex justify-content-between align-items-center">
                    <div class="flex">
                        <p-button class="m-2" icon="pi pi-user" [label]="authenticatedUsername" [rounded]="true" [text]="true" (onClick)="myProfile()" />
                    </div>
                    <div class="flex">
                        <p-button class="m-2" icon="pi pi-sign-out" label="Déconnexion" [rounded]="true" [text]="true" severity="danger" (onClick)="logOut()" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Sidebar footer -->
    </div>
    <!-- Siebar -->
    
    <!-- Portal content -->
    <div class="flex-grow-1 px-3 py-2">
        <p-button icon="pi pi-bars" (onClick)="showSidebarEvent()" class="block xl:hidden" [text]="true" />
        <router-outlet></router-outlet>
    </div>
    <!-- Portal content -->
</div>
<!-- Main structure -->

<!-- Floating sidebar -->
<p-sidebar #sidebarRef [(visible)]="sidebarVisible">
    <ng-template pTemplate="headless">
        <div class="flex flex-column h-full surface-300">
            <!-- Sidebar header -->
            <div class="flex justify-content-between p-2">
                <div class="flex">
                    <p-button icon="pi pi-home" [rounded]="true" [text]="true" (onClick)="goToHomePage()" />
                </div>
                <div class="flex">
                    <p-button icon="pi pi-sun" [rounded]="true" [text]="true" (onClick)="changeTheme('saga-blue')" [hidden]="theme === 'saga-blue'" />
                    <p-button icon="pi pi-moon" [rounded]="true" [text]="true" (onClick)="changeTheme('arya-blue')" [hidden]="theme === 'arya-blue'" />
                </div>
            </div>
            <!-- Sidebar header -->

            <!-- Sidebar structure -->
            <div class="overflow-y-auto mt-5">
                <div class="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
                    <span class="font-semibold text-2xl text-primary">Mon portail</span>
                </div>
                <ul class="list-none p-3 m-0">
                    <li *ngIf="isAdmin || isCommercial || isCustomer || isSuperAdmin">
                        <a [routerLink]="['dashboard']" (click)="closeCallback($event)"
                            class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100">
                            <i class="pi pi-home mr-2"></i>
                            <span class="font-medium">Dashboard</span>
                        </a>
                    </li>
                    <li *ngIf="isSuperAdmin">
                        <div pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer text-700 hover:surface-100">
                            <span class="font-medium">Administration</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 hidden overflow-hidden">
                            <li>
                                <a [routerLink]="['super-admin/manage-companies']" (click)="closeCallback($event)"
                                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                    <span class="text-sm">Partenaires</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="isAdmin">
                        <div pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer text-700 hover:surface-100">
                            <span class="font-medium">Administration</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 hidden overflow-hidden">
                            <li>
                                <a [routerLink]="['pro/admin/manage-portal']" (click)="closeCallback($event)"
                                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                    <span class="text-sm">Mon portail entreprise</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['pro/admin/manage-employees']" (click)="closeCallback($event)"
                                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                    <span class="text-sm">Mes membres</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['pro/admin/manage-customers']" (click)="closeCallback($event)"
                                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                    <span class="text-sm">Mon portefeuille client</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="isCommercial">
                        <div pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer text-700 hover:surface-100">
                            <span class="font-medium">Commercial</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 hidden overflow-hidden">
                            <li>
                                <a [routerLink]="['pro/commercial/my-customers']" (click)="closeCallback($event)"
                                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                    <span class="text-sm">Mes clients</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['pro/commercial/my-leads']" (click)="closeCallback($event)"
                                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                    <span class="text-sm">Mes parrainages</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="isCustomer">
                        <div pStyleClass="@next" enterFromClass="hidden" enterActiveClass="slidedown"
                            leaveToClass="hidden" leaveActiveClass="slideup"
                            class="p-3 flex align-items-center justify-content-between cursor-pointer text-700 hover:surface-100">
                            <span class="font-medium">Client</span>
                            <i class="pi pi-chevron-down"></i>
                        </div>
                        <ul class="list-none p-0 m-0 hidden overflow-hidden">
                            <li>
                                <a [routerLink]="['customer/my-leads']" (click)="closeCallback($event)"
                                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                    <span class="text-sm">Mes parrainages</span>
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['customer/my-companies']" (click)="closeCallback($event)"
                                    class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
                                    <span class="text-sm">Mes entreprises</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <!-- Sidebar structure -->

            <!-- Siebar footer -->
            <div class="mt-auto" *ngIf="isUser">
                <div class="flex justify-content-between align-items-center">
                    <div class="flex">
                        <p-button class="m-2" icon="pi pi-user" [label]="authenticatedUsername" [rounded]="true" [text]="true" (onClick)="myProfile();closeCallback($event)" />
                    </div>
                    <div class="flex">
                        <p-button class="m-2" icon="pi pi-sign-out" label="Déconnexion" [rounded]="true" [text]="true" severity="danger" (onClick)="logOut();closeCallback($event)" />
                    </div>
                </div>
            </div>
            <!-- Siebar footer -->
        </div>
    </ng-template>
</p-sidebar>
<!-- Floating sidebar -->