import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-customer',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './customer.component.html',
  styleUrl: './customer.component.sass'
})
export class CustomerComponent {

}
