import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdateCompanyDetailsRequest } from '../payloads/requests/updateCompanyDetailsRequest';
import { NewEmployeeRequest } from '../payloads/requests/newEmployeeRequest';
import { environment } from '../../environments/environment';
import { NewCustomerRequest } from '../payloads/requests/newCustomerRequest';
import { CheckCustomerRequest } from '../payloads/requests/checkCustomerRequest';

const API = `${environment.rootApiUrl}/api/admin`;

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpClient
  ) { }

  getDetails(): Observable<any> {
    return this.http.get(`${API}/my-company/details`);
  }

  getAllEmployees(): Observable<any> {
    return this.http.get(`${API}/my-company/employees`);
  }

  updateDetails(request: UpdateCompanyDetailsRequest): Observable<any> {
    return this.http.put(`${API}/my-company/details/update`, request);
  }

  addEmployee(request: NewEmployeeRequest): Observable<any> {
    return this.http.post(`${API}/my-company/employees/add`, request);
  }

  checkEmployee(email: string): Observable<any> {
    return this.http.get(`${API}/my-company/employees/add/check-employee/${email}`);
  }

  updateRoles(employeeId: number, isAdmin: boolean, isCommercial: boolean): Observable<any> {
    return this.http.put(`${API}/my-company/employees/${employeeId}/update-roles`, {},
      {
        params: {
          isAdmin: isAdmin,
          isCommercial: isCommercial
        }
      }
    );
  }

  uploadLogo(file: FormData): Observable<any> {
    return this.http.post(`${API}/my-company/upload/logo`, file);
  }

  getDashboardMetrics(): Observable<any> {
    return this.http.get(`${API}/dashboard/metrics`);
  }

  getAllCustomers(): Observable<any> {
    return this.http.get(`${API}/my-company/customers`);
  }

  addCustomer(request: NewCustomerRequest): Observable<any> {
    return this.http.post(`${API}/my-company/customers/add`, request);
  }

  // checkCustomerFromCustomerCreation(email: string): Observable<any> {
  //   return this.http.get(`${API}/my-company/customers/add/check-customer/${email}`);
  // }
  checkCustomerFromCustomerCreation(request: CheckCustomerRequest): Observable<any> {
    return this.http.post(`${API}/my-company/customers/add/check-customer`, request);
  }

  getAllCommercials(): Observable<any> {
    return this.http.get(`${API}/my-company/commercials`);
  }

}
