import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerService } from '../../../../services/customer.service';
import { Subject, takeUntil } from 'rxjs';
// PrimeNG Modules
import { DataViewModule } from 'primeng/dataview';
import { TagModule } from 'primeng/tag';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-my-companies',
  standalone: true,
  imports: [
    DataViewModule,
    TagModule,
    CommonModule,
    ToastModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './my-companies.component.html',
  styleUrl: './my-companies.component.sass'
})
export class MyCompaniesComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  myCompanies: any[] = [];

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private customerService: CustomerService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.customerService.getAllCompanies(false)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myCompanies = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister mes entreprises', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  identifyCompany(index: any, item: any) {
    return item.id;
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
