<!-- Toast module -->
<p-toast></p-toast>
<!-- Toast module -->

<!-- Main structure -->
<div class="block">
    <div>
        <div class="text-3xl font-medium text-900 mb-3 p-2">Mes clients</div>

        <div class="text-xl font-medium text-900 mb-3 p-2" *ngIf="myCustomers.length === 0">Aucun client trouvé...</div>

        <p-dataView #dv [value]="myCustomers" layout="grid" *ngIf="myCustomers.length > 0">
            <ng-template pTemplate="grid" let-customers>
                <div class="grid grid-nogutter">
                    <div class="col-12 sm:col-6 md:col-4 lg:col-3 p-2" *ngFor="let customer of customers; trackBy:identifyCustomer">
                        <div class="flex flex-column p-4 surface-card shadow-2 border-round h-full">
                            <div class="flex justify-content-center surface-200 border-round p-3">
                                <p-avatar icon="pi pi-user" styleClass="mr-2" size="xlarge" shape="circle" />
                            </div>
                            <div class="pt-4">
                                <div class="flex flex-row justify-content-between align-items-start gap-2">
                                    <div class="w-full">
                                        <div class="text-lg font-medium text-900 mt-1 mb-5 overflow-hidden text-overflow-ellipsis">
                                            {{ customer.firstName }} {{ customer.lastName }}
                                        </div>
                                        <div class="mb-2 overflow-hidden text-overflow-ellipsis">
                                            <span class="font-medium">Email: </span>
                                            <span>
                                                <a href="mailto:{{ customer.email }}">
                                                    {{ customer.email }}
                                                </a>
                                            </span>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-medium">Téléphone: </span>
                                            <span>{{ customer.phone }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </div>

    <div class="mt-3">
        <div class="text-3xl font-medium text-900 mb-3 p-2">Mes prospects</div>

        <div class="text-xl font-medium text-900 mb-3 p-2" *ngIf="myProspects.length === 0">Aucun prospect trouvé...</div>

        <p-dataView #dv [value]="myProspects" layout="grid" *ngIf="myProspects.length > 0">
            <ng-template pTemplate="grid" let-prospects>
                <div class="grid grid-nogutter">
                    <div class="col-12 sm:col-6 md:col-4 lg:col-3 p-2" *ngFor="let prospect of prospects; trackBy:identifyProspect">
                        <div class="flex flex-column p-4 surface-card shadow-2 border-round h-full">
                            <div class="flex justify-content-center surface-200 border-round p-3">
                                <p-avatar icon="pi pi-user" styleClass="mr-2" size="xlarge" shape="circle" />
                            </div>
                            <div class="pt-4">
                                <div class="flex flex-row justify-content-between align-items-start gap-2">
                                    <div class="w-full">
                                        <div class="text-lg font-medium text-900 mt-1 mb-5 overflow-hidden text-overflow-ellipsis">
                                            {{ prospect.firstName }} {{ prospect.lastName }}
                                        </div>
                                        <div class="mb-2 overflow-hidden text-overflow-ellipsis">
                                            <span class="font-medium">Email: </span>
                                            <span>
                                                <a href="mailto:{{ prospect.email }}">
                                                    {{ prospect.email }}
                                                </a>
                                            </span>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-medium">Téléphone: </span>
                                            <span>{{ prospect.phone }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-dataView>
    </div>
</div>
<!-- Main structure -->