import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API = `${environment.rootApiUrl}/api/commercial`;

@Injectable({
  providedIn: 'root'
})
export class CommercialService {

  constructor(
    private http: HttpClient
  ) { }

  getAllAsCommercial(): Observable<any> {
    return this.http.get(`${API}/leads`);
  }

  getDetails(leadId: number): Observable<any> {
    return this.http.get(`${API}/leads/${leadId}/details`);
  }

  getAllStatus(): Observable<any> {
    return this.http.get(`${API}/leads/status`);
  }

  updateStatus(leadId: number, statusId: number): Observable<any> {
    return this.http.put(`${API}/leads/${leadId}/update-status`, {},
      {
        params: {
          statusId: statusId
        }
      }
    );
  }

  addNote(leadId: number, note: string): Observable<any> {
    return this.http.post(`${API}/leads/${leadId}/add-note`, note);
  }

  getAllCustomers(): Observable<any> {
    return this.http.get(`${API}/customers`);
  }

  getAllProspects(): Observable<any> {
    return this.http.get(`${API}/prospects`);
  }

  getDashboardMetrics(): Observable<any> {
    return this.http.get(`${API}/dashboard/metrics`);
  }

}
