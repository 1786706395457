export class NewCompanyRequest {

  name: string;
  siren: string;
  email: string;
  phone: string;
  adminEmail: string;
  adminLastName: string;
  adminFirstName: string;
  adminPhone: string;

  constructor(name: string, siren: string, email: string, phone: string, adminEmail: string, adminLastName: string, adminFirstName: string, adminPhone: string) {
    this.name = name;
    this.siren = siren;
    this.email = email;
    this.phone = phone;
    this.adminEmail = adminEmail;
    this.adminLastName = adminLastName;
    this.adminFirstName = adminFirstName;
    this.adminPhone = adminPhone;
  }
}