<!-- Toast module -->
<p-toast></p-toast>
<!-- Toast module -->

<!-- Main structure -->
<div class="block">
    <div>
        <div class="text-3xl font-medium text-900 mb-3 p-2">Mon profile</div>

        <div class="flex flex-column p-4 surface-card shadow-2 border-round">
            <form [formGroup]="updateMyProfileForm" (ngSubmit)="onSubmitUpdatePrincipalProfile()">
                <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Nom d'utilisateur</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{ myProfile?.username }}</div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Nom</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hideLastName">{{
                            myProfile?.lastName }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hideLastName">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myProfile?.lastName" pInputText
                                    formControlName="lastName" aria-describedby="lastName-edit">
                                <small class="text-red-600" id="lastName-edit"
                                    *ngIf="updateMyProfileForm.controls.lastName.errors?.['pattern']">
                                    Règles: que des lettres, entre 2 et 30 caractères.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('lastName')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Prénom</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hideFirstName">{{
                            myProfile?.firstName }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hideFirstName">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myProfile?.firstName" pInputText
                                    formControlName="firstName" aria-describedby="firstName-edit">
                                <small class="text-red-600" id="firstName-edit"
                                    *ngIf="updateMyProfileForm.controls.firstName.errors?.['pattern']">
                                    Règles: que des lettres, entre 2 et 30 caractères.    
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('firstName')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Email</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hideEmail">{{
                            myProfile?.email }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hideEmail">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myProfile?.email" pInputText formControlName="email"
                                    aria-describedby="email-edit">
                                <small class="text-red-600" id="email-edit"
                                    *ngIf="updateMyProfileForm.controls.email.errors?.['pattern']">
                                    Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('email')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Téléphone</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hidePhone">{{
                            myProfile?.phone }}</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hidePhone">
                            <div class="flex flex-column">
                                <input type="text" [placeholder]="myProfile?.phone" pInputText formControlName="phone"
                                    aria-describedby="phone-edit">
                                <small class="text-red-600" id="phone-edit"
                                    *ngIf="updateMyProfileForm.controls.phone.errors?.['pattern']">
                                    Règles: 10 chiffres.
                                </small>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('phone')" />
                        </div>
                    </li>
                    <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                        <div class="text-500 w-6 md:w-2 font-medium">Mot de passe</div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="hidePassword">*****
                        </div>
                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" [hidden]="!hidePassword">
                            <div class="flex flex-column">
                                <div class="flex mt-3">
                                    <p-floatLabel>
                                        <p-password formControlName="currentPassword" [feedback]="false"
                                            [toggleMask]="true" />
                                        <label>Mot de passe actuel</label>
                                    </p-floatLabel>
                                </div>
                                <div class="flex mt-4">
                                    <div class="flex flex-column">
                                        <p-floatLabel>
                                            <p-password [toggleMask]="true" [strongRegex]="passwordRegex"
                                                formControlName="newPassword" promptLabel="Choisir un mot de passe">
                                                <ng-template pTemplate="footer">
                                                    <p-divider></p-divider>
                                                    <p class="mt-2">Règles</p>
                                                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                                                        <li
                                                            [ngClass]="pwdLowerCase() ? 'text-green-600' : 'text-red-900'">
                                                            Au moins 1 minuscule</li>
                                                        <li
                                                            [ngClass]="pwdUpperCase() ? 'text-green-600' : 'text-red-900'">
                                                            au moins 1 majuscule</li>
                                                        <li [ngClass]="pwdDigit() ? 'text-green-600' : 'text-red-900'">
                                                            Au
                                                            moins 1 chiffre</li>
                                                        <li
                                                            [ngClass]="pwdSpecialChar() ? 'text-green-600' : 'text-red-900'">
                                                            Au moins 1 caractère spécial: !&#64;#$%^&*()_+</li>
                                                        <li [ngClass]="pwdLength() ? 'text-green-600' : 'text-red-900'">
                                                            Entre 12 et 20 caractères</li>
                                                    </ul>
                                                </ng-template>
                                            </p-password>
                                            <label>Nouveau mot de passe</label>
                                        </p-floatLabel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-6 md:w-2 flex justify-content-end">
                            <p-button icon="pi pi-pencil" [rounded]="true" [text]="true"
                                (onClick)="onClickEnableFieldInput('password')" />
                        </div>
                    </li>
                    <div class="w-full flex justify-content-end mt-3">
                        <p-button icon="pi pi-save" [loading]="getLoading()"
                            label="Mettre à jour" [rounded]="true" [outlined]="true" type="submit"
                            [disabled]="!updateMyProfileForm.valid || getLoading()" />
                    </div>
                </ul>
            </form>
        </div>
    </div>
</div>
<!-- Main structure -->