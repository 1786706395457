import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(
    private jwtService: JwtService,
    private router: Router,
    private loadingService: LoadingService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.totalRequests++;
    this.loadingService.show();

    if (!this.router.url.includes('home') && !this.router.url.includes('login')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.jwtService.getToken()}`
        }
      });
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loadingService.hide();
        }
      }),
      catchError(err => {
        const error = err.error;

        if(error.status === 401) {
          this.jwtService.clear();
          this.router.navigate(['login'], { queryParams: { error: error.description } })
        }

        if (error.status === 403) {
          if (
            error.description === "The JWT signature is invalid" ||
            error.description === "The account is locked" ||
            error.description === "The JWT token has expired") {
              this.jwtService.clear();
              this.router.navigate(['login'], { queryParams: { error: error.description } });
          } else {
            this.router.navigate(['forbidden']);
          }
        }

        if (error.status === 404) {
          this.router.navigate(['not-found']);
        }

        return throwError(() => err.error);
      }));
  }
}

export const httpInterceptorProviders = [
  provideHttpClient(
    withInterceptorsFromDi()
  ),
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtTokenInterceptor,
    multi: true
  }
]