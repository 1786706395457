import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewLeadRequest } from '../payloads/requests/newLeadRequest';
import { environment } from '../../environments/environment';
import { CheckCustomerRequest } from '../payloads/requests/checkCustomerRequest';

const API = `${environment.rootApiUrl}/api/customer`;

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private http: HttpClient
  ) { }

  getAllAsCustomer(): Observable<any> {
    return this.http.get(`${API}/leads`);
  }

  getDetails(leadId: number): Observable<any> {
    return this.http.get(`${API}/leads/${leadId}/details`);
  }

  addNote(leadId: number, note: string): Observable<any> {
    return this.http.post(`${API}/leads/${leadId}/add-note`, note);
  }

  getAllCompanies(leadCreationMode: boolean): Observable<any> {
    return this.http.get(`${API}/my-companies/${leadCreationMode}`);
  }

  create(request: NewLeadRequest): Observable<any> {
    return this.http.post(`${API}/leads/create`, request);
  }

  getDashboardMetrics(): Observable<any> {
    return this.http.get(`${API}/dashboard/metrics`);
  }

  // checkCustomerFromLeadCreation(email: string): Observable<any> {
  //   return this.http.get(`${API}/leads/create/check-customer/${email}`);
  // }

  checkCustomerFromLeadCreation(request: CheckCustomerRequest): Observable<any> {
    return this.http.post(`${API}/leads/create/check-customer`, request);
  }

}
