import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ContactFormRequest } from '../payloads/requests/contactFormRequest';

const API = `${environment.rootApiUrl}/api/public`;

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<any> {
    return this.http.get(`${API}/partners`);
  }

  contactService(request: ContactFormRequest): Observable<any> {
    return this.http.post(`${API}/contact`, request);
  }
}
