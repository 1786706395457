import { trigger, state, style, transition, animate } from '@angular/animations';
import * as i2 from '@angular/common';
import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { PLATFORM_ID, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Input, ContentChildren, NgModule } from '@angular/core';
import * as i1 from 'primeng/api';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { ChevronUpIcon } from 'primeng/icons/chevronup';
import { ZIndexUtils } from 'primeng/utils';

/**
 * ScrollTop gets displayed after a certain scroll position and used to navigates to the top of the page quickly.
 * @group Components
 */
const _c0 = (a0, a1) => ({
  showTransitionParams: a0,
  hideTransitionParams: a1
});
const _c1 = a0 => ({
  value: "open",
  params: a0
});
const _c2 = () => ({
  styleClass: "p-scrolltop-icon"
});
const _c3 = () => ({
  "font-size": "1rem",
  scale: "1.5"
});
function ScrollTop_button_0_ng_container_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 6);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵclassMap(ctx_r1.icon);
    i0.ɵɵproperty("ngClass", "p-scrolltop-icon");
  }
}
function ScrollTop_button_0_ng_container_1_ChevronUpIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ChevronUpIcon", 7);
  }
  if (rf & 2) {
    i0.ɵɵproperty("styleClass", "p-scrolltop-icon")("ngStyle", i0.ɵɵpureFunction0(2, _c3));
  }
}
function ScrollTop_button_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ScrollTop_button_0_ng_container_1_span_1_Template, 1, 3, "span", 4)(2, ScrollTop_button_0_ng_container_1_ChevronUpIcon_2_Template, 1, 3, "ChevronUpIcon", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.icon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.icon);
  }
}
function ScrollTop_button_0_2_ng_template_0_Template(rf, ctx) {}
function ScrollTop_button_0_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ScrollTop_button_0_2_ng_template_0_Template, 0, 0, "ng-template", 8);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", !ctx_r1.icon);
  }
}
function ScrollTop_button_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 1);
    i0.ɵɵlistener("@animation.start", function ScrollTop_button_0_Template_button_animation_animation_start_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onEnter($event));
    })("@animation.done", function ScrollTop_button_0_Template_button_animation_animation_done_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onLeave($event));
    })("click", function ScrollTop_button_0_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onClick());
    });
    i0.ɵɵtemplate(1, ScrollTop_button_0_ng_container_1_Template, 3, 2, "ng-container", 2)(2, ScrollTop_button_0_2_Template, 1, 1, null, 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.styleClass);
    i0.ɵɵproperty("@animation", i0.ɵɵpureFunction1(12, _c1, i0.ɵɵpureFunction2(9, _c0, ctx_r1.showTransitionOptions, ctx_r1.hideTransitionOptions)))("ngClass", ctx_r1.containerClass())("ngStyle", ctx_r1.style);
    i0.ɵɵattribute("aria-label", ctx_r1.buttonAriaLabel);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.iconTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.iconTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction0(14, _c2));
  }
}
class ScrollTop {
  document;
  platformId;
  renderer;
  el;
  cd;
  config;
  /**
   * Class of the element.
   * @group Props
   */
  styleClass;
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Target of the ScrollTop.
   * @group Props
   */
  target = 'window';
  /**
   * Defines the threshold value of the vertical scroll position of the target to toggle the visibility.
   * @group Props
   */
  threshold = 400;
  /**
   * Name of the icon or JSX.Element for icon.
   * @group Props
   */
  icon;
  /**
   * Defines the scrolling behavior, "smooth" adds an animation and "auto" scrolls with a jump.
   * @group Props
   */
  behavior = 'smooth';
  /**
   * A string value used to determine the display transition options.
   * @group Props
   */
  showTransitionOptions = '.15s';
  /**
   * A string value used to determine the hiding transition options.
   * @group Props
   */
  hideTransitionOptions = '.15s';
  /**
   * Establishes a string value that labels the scroll-top button.
   * @group Props
   */
  buttonAriaLabel;
  templates;
  iconTemplate;
  documentScrollListener;
  parentScrollListener;
  visible = false;
  overlay;
  window;
  constructor(document, platformId, renderer, el, cd, config) {
    this.document = document;
    this.platformId = platformId;
    this.renderer = renderer;
    this.el = el;
    this.cd = cd;
    this.config = config;
    this.window = this.document.defaultView;
  }
  ngOnInit() {
    if (this.target === 'window') this.bindDocumentScrollListener();else if (this.target === 'parent') this.bindParentScrollListener();
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'icon':
          this.iconTemplate = item.template;
          break;
      }
    });
  }
  onClick() {
    let scrollElement = this.target === 'window' ? this.window : this.el.nativeElement.parentElement;
    scrollElement.scroll({
      top: 0,
      behavior: this.behavior
    });
  }
  onEnter(event) {
    switch (event.toState) {
      case 'open':
        this.overlay = event.element;
        ZIndexUtils.set('overlay', this.overlay, this.config.zIndex.overlay);
        break;
      case 'void':
        this.overlay = null;
        break;
    }
  }
  onLeave(event) {
    switch (event.toState) {
      case 'void':
        ZIndexUtils.clear(event.element);
        break;
    }
  }
  checkVisibility(scrollY) {
    if (scrollY > this.threshold) this.visible = true;else this.visible = false;
    this.cd.markForCheck();
  }
  bindParentScrollListener() {
    if (isPlatformBrowser(this.platformId)) {
      this.parentScrollListener = this.renderer.listen(this.el.nativeElement.parentElement, 'scroll', () => {
        this.checkVisibility(this.el.nativeElement.parentElement.scrollTop);
      });
    }
  }
  bindDocumentScrollListener() {
    if (isPlatformBrowser(this.platformId)) {
      this.documentScrollListener = this.renderer.listen(this.window, 'scroll', () => {
        this.checkVisibility(DomHandler.getWindowScrollTop());
      });
    }
  }
  unbindParentScrollListener() {
    if (this.parentScrollListener) {
      this.parentScrollListener();
      this.parentScrollListener = null;
    }
  }
  unbindDocumentScrollListener() {
    if (this.documentScrollListener) {
      this.documentScrollListener();
      this.documentScrollListener = null;
    }
  }
  containerClass() {
    return {
      'p-scrolltop p-link p-component': true,
      'p-scrolltop-sticky': this.target !== 'window'
    };
  }
  ngOnDestroy() {
    if (this.target === 'window') this.unbindDocumentScrollListener();else if (this.target === 'parent') this.unbindParentScrollListener();
    if (this.overlay) {
      ZIndexUtils.clear(this.overlay);
      this.overlay = null;
    }
  }
  static ɵfac = function ScrollTop_Factory(t) {
    return new (t || ScrollTop)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.PrimeNGConfig));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ScrollTop,
    selectors: [["p-scrollTop"]],
    contentQueries: function ScrollTop_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      styleClass: "styleClass",
      style: "style",
      target: "target",
      threshold: [2, "threshold", "threshold", numberAttribute],
      icon: "icon",
      behavior: "behavior",
      showTransitionOptions: "showTransitionOptions",
      hideTransitionOptions: "hideTransitionOptions",
      buttonAriaLabel: "buttonAriaLabel"
    },
    features: [i0.ɵɵInputTransformsFeature],
    decls: 1,
    vars: 1,
    consts: [["type", "button", 3, "ngClass", "class", "ngStyle", "click", 4, "ngIf"], ["type", "button", 3, "click", "ngClass", "ngStyle"], [4, "ngIf"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [3, "class", "ngClass", 4, "ngIf"], [3, "styleClass", "ngStyle", 4, "ngIf"], [3, "ngClass"], [3, "styleClass", "ngStyle"], [3, "ngIf"]],
    template: function ScrollTop_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, ScrollTop_button_0_Template, 3, 15, "button", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.visible);
      }
    },
    dependencies: () => [i2.NgClass, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, ChevronUpIcon],
    styles: ["@layer primeng{.p-scrolltop{position:fixed;bottom:20px;right:20px;display:flex;align-items:center;justify-content:center}.p-scrolltop-sticky{position:sticky}.p-scrolltop-sticky.p-link{margin-left:auto}}\n"],
    encapsulation: 2,
    data: {
      animation: [trigger('animation', [state('void', style({
        opacity: 0
      })), state('open', style({
        opacity: 1
      })), transition('void => open', animate('{{showTransitionParams}}')), transition('open => void', animate('{{hideTransitionParams}}'))])]
    },
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollTop, [{
    type: Component,
    args: [{
      selector: 'p-scrollTop',
      template: `
        <button
            *ngIf="visible"
            [@animation]="{ value: 'open', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }"
            (@animation.start)="onEnter($event)"
            (@animation.done)="onLeave($event)"
            [attr.aria-label]="buttonAriaLabel"
            [ngClass]="containerClass()"
            (click)="onClick()"
            [class]="styleClass"
            [ngStyle]="style"
            type="button"
        >
            <ng-container *ngIf="!iconTemplate">
                <span *ngIf="icon" [class]="icon" [ngClass]="'p-scrolltop-icon'"></span>
                <ChevronUpIcon *ngIf="!icon" [styleClass]="'p-scrolltop-icon'" [ngStyle]="{ 'font-size': '1rem', scale: '1.5' }" />
            </ng-container>
            <ng-template [ngIf]="!icon" *ngTemplateOutlet="iconTemplate; context: { styleClass: 'p-scrolltop-icon' }"></ng-template>
        </button>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      animations: [trigger('animation', [state('void', style({
        opacity: 0
      })), state('open', style({
        opacity: 1
      })), transition('void => open', animate('{{showTransitionParams}}')), transition('open => void', animate('{{hideTransitionParams}}'))])],
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-scrolltop{position:fixed;bottom:20px;right:20px;display:flex;align-items:center;justify-content:center}.p-scrolltop-sticky{position:sticky}.p-scrolltop-sticky.p-link{margin-left:auto}}\n"]
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i1.PrimeNGConfig
  }], {
    styleClass: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    target: [{
      type: Input
    }],
    threshold: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    icon: [{
      type: Input
    }],
    behavior: [{
      type: Input
    }],
    showTransitionOptions: [{
      type: Input
    }],
    hideTransitionOptions: [{
      type: Input
    }],
    buttonAriaLabel: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class ScrollTopModule {
  static ɵfac = function ScrollTopModule_Factory(t) {
    return new (t || ScrollTopModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ScrollTopModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, ChevronUpIcon, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollTopModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ChevronUpIcon, SharedModule],
      exports: [ScrollTop, SharedModule],
      declarations: [ScrollTop]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ScrollTop, ScrollTopModule };
