export class UpdateCompanyDetailsRequest {

  name: string | null;
  email: string | null;
  phone: string | null;
  description: string | null;

  constructor(name: string | null, email: string | null, phone: string | null, description: string | null) {
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.description = description;
  }
}