<!-- Main structure -->
<div class="block px-2 py-1 shadow-6 text-800">
    <div class="flex justify-content-between">
        <div class="flex align-content-center">
            <div class="font-bold align-content-center">
                <span>Link</span>
                <span class="text-blue-400">Upp</span>
            </div>
        </div>
        <div class="hidden md:flex flex-1 justify-content-end border-right-1 mr-3 border-400">
            <div class="gap-3 align-content-center pr-4">
                <div class="flex gap-3 justify-content-end text-sm font-medium">
                    <span class="flex" (click)="scrollIntoView('#advantages')" class="cursor-pointer">AVANTAGES</span>
                    <span class="flex" (click)="scrollIntoView('#partners')" class="cursor-pointer">PARTENAIRES</span>
                    <span class="flex" (click)="scrollIntoView('#contacts')" class="cursor-pointer">CONTACTS</span>
                </div>
            </div>
        </div>
        <div class="flex align-content-center">
            <p-button icon="pi pi-user" [rounded]="true" [text]="true" (onClick)="goToMyAccount()" />
            <p-button icon="pi pi-sun" [rounded]="true" [text]="true" (onClick)="changeTheme('saga-blue')" [hidden]="theme === 'saga-blue'" />
            <p-button icon="pi pi-moon" [rounded]="true" [text]="true" (onClick)="changeTheme('arya-blue')" [hidden]="theme === 'arya-blue'" />
        </div>
    </div>
</div>
<!-- Main structure -->