import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { UpdateCompanyDetailsRequest } from '../../../../../payloads/requests/updateCompanyDetailsRequest';
import { AdminService } from '../../../../../services/admin.service';
import { LoadingService } from '../../../../../services/loading.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule, IMAGE_CONFIG } from '@angular/common';
// PrimeNG modules
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService } from 'primeng/api';
import { FileUpload, FileUploadHandlerEvent, FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { TagModule } from 'primeng/tag';
import { ImageModule } from 'primeng/image';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-manage-portal',
  standalone: true,
  imports: [
    ButtonModule,
    StyleClassModule,
    CommonModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    TagModule,
    FileUploadModule,
    ToastModule,
    ImageModule
  ],
  providers: [
    MessageService,
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true
      }
    }
  ],
  templateUrl: './manage-portal.component.html',
  styleUrl: './manage-portal.component.sass'
})
export class ManagePortalComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  myCompanyDetails: any;

  updateMyCompanyDetailsForm = this.formBuilder.group({
    name: [null, Validators.pattern('^[a-zA-ZÀ-ÿ0-9\\s-]{2,50}$')],
    email: [null, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')],
    phone: [null, Validators.pattern('^[0-9]{10}$')],
    description: [null, Validators.pattern('^.{30,255}$')]
  }, { validators: [this.requireAtLeastOneFilledValidator()] })

  hideName: boolean = false;
  hideEmail: boolean = false;
  hidePhone: boolean = false;
  hideDescription: boolean = false;

  @ViewChild('fileUpload') fileUpload!: FileUpload;

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.adminService.getDetails()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myCompanyDetails = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: "Lister les détails de l'entreprise", detail: error.description, life: 5000 });
        }
      });
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  getLoading() {
    return this.loadingService.get();
  }

  onClickEnableFieldInput(field: string) {
    switch (field) {
      case 'name': {
        this.hideName = !this.hideName;
        this.updateMyCompanyDetailsForm.patchValue({ name: null });
        break;
      }
      case 'email': {
        this.hideEmail = !this.hideEmail;
        this.updateMyCompanyDetailsForm.patchValue({ email: null });
        break;
      }
      case 'phone': {
        this.hidePhone = !this.hidePhone;
        this.updateMyCompanyDetailsForm.patchValue({ phone: null });
        break;
      }
      case 'description': {
        this.hideDescription = !this.hideDescription;
        this.updateMyCompanyDetailsForm.patchValue({ description: null });
        break;
      }
    }
  }

  requireAtLeastOneFilledValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasFilledControls = Object.values(control.value).some(
        (value: any) => value !== null && value !== undefined && value !== ''
      );

      return hasFilledControls ? null : { requireAtLeastOneFilled: true };
    };
  }

  onSubmitUpdateCompany() {
    const request: UpdateCompanyDetailsRequest = new UpdateCompanyDetailsRequest(
      this.updateMyCompanyDetailsForm.get('name')?.value!,
      this.updateMyCompanyDetailsForm.get('email')?.value!,
      this.updateMyCompanyDetailsForm.get('phone')?.value!,
      this.updateMyCompanyDetailsForm.get('description')?.value!);
    this.adminService.updateDetails(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myCompanyDetails = response;

          this.hideName = false;
          this.hideEmail = false;
          this.hidePhone = false;
          this.hideDescription = false;

          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entreprise mise à jour' });
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: "Mise à jour de l'entreprise", detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }

  choose(event: any, callback: any) {
    callback();
  }

  uploadEvent(callback: any) {
    callback();
  }

  uploadFile(event: FileUploadHandlerEvent) {
    var file: File = event.files[0];
    let formdata: FormData = new FormData();
    formdata.append('file', file, file.name);
    this.adminService.uploadLogo(formdata)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myCompanyDetails = response;
          this.fileUpload.clear();
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Logo téléchargé et mis à jour' });
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Téléchargement du logo', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
