<!-- Main structure -->
<div class="block">
    <div>
        <div class="text-3xl font-medium text-900 mb-3 p-2">Mes parrainages</div>

        <div class="text-xl font-medium text-900 mb-3 p-2" *ngIf="myLeads.length === 0">Aucun parrainage trouvé...</div>

        <p-table #dt1 [value]="myLeads" selectionMode="single" [(selection)]="selectedLead"
            (onRowSelect)="onRowSelectLead($event)" [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack"
            [breakpoint]="'767px'" sortField="createdAt" [sortOrder]="-1"
            [globalFilterFields]="['createdAt', 'company.name', 'commercial.firstName', 'godFather.firstName', 'godChild.firstName', 'lead.leadStatus.label']"
            [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="myLeads.length > 0">

            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Chercher..." />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="createdAt">Date de création<p-sortIcon field="createdAt" /></th>
                    <th pSortableColumn="company.name">Entreprise<p-sortIcon field="company.name" /></th>
                    <th pSortableColumn="commercial.firstName">Commercial<p-sortIcon field="commercial.firstName" /></th>
                    <th pSortableColumn="godFather.firstName">Parrain<p-sortIcon field="godFather.firstName" /></th>
                    <th pSortableColumn="godChild.firstName">Filleul<p-sortIcon field="godChild.firstName" /></th>
                    <th pSortableColumn="lead.leadStatus.label">Statut<p-sortIcon field="lead.leadStatus.label" /></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-lead>
                <tr [pSelectableRow]="lead">
                    <td>
                        <span class="md:hidden font-medium">Date:&nbsp;</span>
                        <span>{{ lead.createdAt | date: 'mediumDate' }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Entreprise:&nbsp;</span>
                        <span>{{ lead.company.name }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Commercial:&nbsp;</span>
                        <span>{{ lead.commercial.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Parrain:&nbsp;</span>
                        <span>{{ lead.godFather.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Filleul:&nbsp;</span>
                        <span>{{ lead.godChild.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Statut:&nbsp;</span>
                        <span>
                            <p-tag severity="warning" [value]="lead.leadStatus.label" [rounded]="true" *ngIf="lead.leadStatus.label != 'Réalisé/complété' && lead.leadStatus.label != 'Annulé'"/>
                            <p-tag severity="success" [value]="lead.leadStatus.label" [rounded]="true" *ngIf="lead.leadStatus.label === 'Réalisé/complété'"/>
                            <p-tag severity="danger" [value]="lead.leadStatus.label" [rounded]="true" *ngIf="lead.leadStatus.label === 'Annulé'"/>
                        </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<!-- Main structure -->