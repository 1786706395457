<!-- Main structure -->
<div class="block h-screen surface-300">
    <div class="h-full px-4 py-8 md:px-6 lg:px-8 flex justify-content-center">
        <div class="text-700 text-center flex flex-column align-items-center justify-content-center">
            <div class="flex text-900 font-bold text-5xl mb-3">404 not found</div>
            <div class="flex text-700 text-2xl mb-3">La page ou ressource demandée n'existe pas ou a été déplacée.</div>
            <p-button icon="pi pi-home" [rounded]="true" [text]="true" (onClick)="goToHomePage()" />
        </div>
    </div>
</div>
<!-- Main structure -->