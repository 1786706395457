export class NewLeadRequest {

  companyId: number;
  godChildFirstName: string;
  godChildLastName: string;
  godChildEmail: string;
  godChildPhone: string;

  constructor(companyId: number, godChildFirstName: string, godChildLastName: string, godChildEmail: string, godChildPhone: string) {
    this.companyId = companyId;
    this.godChildFirstName = godChildFirstName;
    this.godChildLastName = godChildLastName;
    this.godChildEmail = godChildEmail;
    this.godChildPhone = godChildPhone;
  }

}