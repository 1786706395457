export class NewEmployeeRequest {

  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isAdmin: boolean;
  isCommercial: boolean;

  constructor(firstName: string, lastName: string, email: string, phone: string, isAdmin: boolean, isCommercial: boolean) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.isAdmin = isAdmin;
    this.isCommercial = isCommercial;
  }

}