export class CheckCustomerRequest {
  
  companyId: number | null;
  email: string;

  constructor(companyId: number | null, email: string) {
    this.companyId = companyId;
    this.email = email;
  }

}