<!-- Toast module -->
<p-toast></p-toast>
<!-- Toast module -->

<!-- Main structure -->
<div class="block">
    <div>
        <div class="text-3xl font-medium text-900 mb-3 p-2">Mes parrainages</div>

        <div class="flex justify-content-between mb-3 p-2" *ngIf="myLeads.length === 0">
            <div class="text-xl font-medium text-900">Aucun parrainage trouvé...</div>
            <p-button icon="pi pi-plus" label="Créer" (click)="showNewLeadDialog()" [rounded]="true" [text]="true" />
        </div>

        <p-table #dt1 [value]="myLeads" selectionMode="single" [(selection)]="selectedLead"
            (onRowSelect)="onRowSelectLead($event)" [tableStyle]="{'min-width': '1rem'}" responsiveLayout="stack"
            [breakpoint]="'767px'" sortField="createdAt" [sortOrder]="-1"
            [globalFilterFields]="['createdAt', 'company.name', 'commercial.firstName', 'godFather.firstName', 'godChild.firstName', 'leadStatus.label']"
            [paginator]="true" [rows]="5" [rowsPerPageOptions]="[5, 10, 15, 20]" *ngIf="myLeads.length > 0">

            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-end gap-2">
                    <p-button icon="pi pi-plus" label="Parrainer" [rounded]="true" [text]="true"
                        (onClick)="showNewLeadDialog()" />
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                            placeholder="Chercher..." />
                    </span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="createdAt">Date de création<p-sortIcon field="createdAt" /></th>
                    <th pSortableColumn="company.name">Entreprise<p-sortIcon field="company.name" /></th>
                    <th pSortableColumn="commercial.firstName">Commercial<p-sortIcon field="commercial.firstName" /></th>
                    <th pSortableColumn="godFather.firstName">Parrain<p-sortIcon field="godFather.firstName" /></th>
                    <th pSortableColumn="godChild.firstName">Filleul<p-sortIcon field="godChild.firstName" /></th>
                    <th pSortableColumn="leadStatus.label">Statut<p-sortIcon field="leadStatus.label" /></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-lead>
                <tr [pSelectableRow]="lead">
                    <td>
                        <span class="md:hidden font-medium">Date:&nbsp;</span>
                        <span>{{ lead.createdAt | date: 'mediumDate' }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Entreprise:&nbsp;</span>
                        <span>{{ lead.company.name }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Commercial:&nbsp;</span>
                        <span>{{ lead.commercial.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Parrain:&nbsp;</span>
                        <span>{{ lead.godFather.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Filleul:&nbsp;</span>
                        <span>{{ lead.godChild.firstName }}</span>
                    </td>
                    <td>
                        <span class="md:hidden font-medium">Statut:&nbsp;</span>
                        <span>
                            <p-tag severity="warning" [value]="lead.leadStatus.label" [rounded]="true"
                                *ngIf="lead.leadStatus.label != 'Réalisé/complété' && lead.leadStatus.label != 'Annulé'" />
                            <p-tag severity="success" [value]="lead.leadStatus.label" [rounded]="true"
                                *ngIf="lead.leadStatus.label === 'Réalisé/complété'" />
                            <p-tag severity="danger" [value]="lead.leadStatus.label" [rounded]="true"
                                *ngIf="lead.leadStatus.label === 'Annulé'" />
                        </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</div>
<!-- Main structure -->

<!-- New lead dialog -->
<p-dialog header="Parrainer un amis" [(visible)]="newLeadDialogIsVisible" [modal]="true"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '50vw' }" [draggable]="false"
    [resizable]="false">
    <form [formGroup]="newLeadForm" (ngSubmit)="onSubmitNewLead()">
        <ul class="list-none p-0 m-0 mb-3">
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Entreprise</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <p-dropdown formControlName="company" [options]="myCompanies" optionLabel="companyName"
                        placeholder="Choisir une entreprise" (onChange)="onChangeCompany($event)"></p-dropdown>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Commercial</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    {{ commercialFullName }}
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Email filleul</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Email" pInputText formControlName="godChildEmail"
                            aria-describedby="email-edit" (focusout)="focusOutGodChildEmail()">
                        <small class="text-red-600" id="email-edit"
                            *ngIf="newLeadForm.controls.godChildEmail.errors?.['pattern']">
                            Règles: format email (xx&#64;xx.xx), entre 6 et 50 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Nom filleul</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Nom" pInputText formControlName="godChildLastName"
                            aria-describedby="lastName-edit">
                        <small class="text-red-600" id="lastName-edit"
                            *ngIf="newLeadForm.controls.godChildLastName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Prénom filleul</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Prénom" pInputText formControlName="godChildFirstName"
                            aria-describedby="firstName-edit">
                        <small class="text-red-600" id="firstName-edit"
                            *ngIf="newLeadForm.controls.godChildFirstName.errors?.['pattern']">
                            Règles: que des lettres, entre 2 et 30 caractères.
                        </small>
                    </div>
                </div>
            </li>
            <li class="flex align-items-center py-3 px-2 surface-border flex-wrap">
                <div class="text-500 w-6 md:w-2 font-medium">Téléphone filleul</div>
                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                    <div class="flex flex-column gap-2">
                        <input type="text" placeholder="Téléphone" pInputText formControlName="godChildPhone"
                            aria-describedby="phone-edit">
                        <small class="text-red-600" id="phone-edit"
                            *ngIf="newLeadForm.controls.godChildPhone.errors?.['pattern']">
                            Règles: 10 chiffres.
                        </small>
                    </div>
                </div>
            </li>
        </ul>

        <div class="flex flex-row gap-3">
            <div class="flex">
                <p-checkbox formControlName="legalNoticesAccepted" [binary]="true" />
            </div>
            <div class="flex">
                <p-scrollPanel [style]="{ width: '100%', height: '100px' }">
                    « Les informations recueillies par <span class="font-medium font-italic">LINKUPP</span> font l’objet d’un
                    traitement informatique ayant pour finalité la gestion de notre fichier clients / prospects, 
                    <span class="font-medium font-italic">LINKUPP</span> ayant un intérêt légitime à développer et entretenir une
                    relation commerciale avec ses clients / prospects. Nous vous assurons qu’aucune donnée
                    ne sera communiquée, cédée ni revendue à des tiers. Les données seront conservées pour
                    une durée maximale de 3 ans après la fin de la relation commerciale, ou après votre
                    dernière demande. Conformément à la loi « Informatique et Libertés » du 6 janvier 1978
                    modifiée et au Règlement Européen 2016/679 du 27 avril 2016 relatif à la protection des
                    personnes physiques à l’égard du traitement des données à caractère personnel, vous
                    disposez d’un droit d’accès et de rectification aux informations qui vous concernent ainsi que
                    d’un droit d’opposition, du droit à la limitation du traitement et à l’effacement dans le cadre
                    de la réglementation en vigueur. Vous pouvez exercer ces droits, il vous suffit d’envoyer un
                    email à cette adresse : <span class="font-medium font-italic">contact&#64;linkupp.fr</span>. Si vous estimez, après nous avoir contactés, que vos
                    droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation
                    à la CNIL. »
                </p-scrollPanel>
            </div>
        </div>

        <div class="flex gap-2 justify-content-end mt-5">
            <p-button icon="pi pi-times" label="Annuler" [rounded]="true" [outlined]="true" severity="danger"
                (onClick)="closeNewLeadDialog()" />
            <p-button type="submit" icon="pi pi-save" [loading]="getLoading()"
                label="Envoyer" [rounded]="true" [outlined]="true"
                [disabled]="!newLeadForm.valid || getLoading()" />
        </div>
    </form>
</p-dialog>
<!-- New lead dialog -->