import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { JwtService } from '../../services/jwt.service';
import { HeaderComponent } from '../header/header.component';
import { AuthenticationService } from '../../services/authentication.service';
import { ThemeService } from '../../services/theme.service';
import { Subject, takeUntil } from 'rxjs';
// PrimeNG Modules
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { StyleClassModule } from 'primeng/styleclass';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-portal',
  standalone: true,
  imports: [
    StyleClassModule,
    CommonModule,
    HeaderComponent,
    SidebarModule,
    ButtonModule,
    AvatarModule,
    RouterModule,
    ToastModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './portal.component.html',
  styleUrl: './portal.component.sass'
})
export class PortalComponent implements OnInit, OnDestroy {

  //#region COMPONENTS VARIABLES
  @ViewChild('sidebarRef') sidebarRef!: Sidebar;
  sidebarVisible: boolean = false;

  interval: any;

  theme: string = 'saga-blue';

  authenticatedUsername: string = '';
  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;
  isCommercial: boolean = false;
  isCustomer: boolean = false;
  isUser: boolean = false;

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private jwtService: JwtService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    const theme: string = localStorage.getItem("linkupp_theme") || "";
    if (theme === 'saga-blue' || theme === 'arya-blue') {
      this.theme = theme;
      this.changeTheme(theme)
    }

    this.timerCheckJwtValidity();

    this.authenticatedUsername = this.getAuthenticatedUserUsername();

    this.isSuperAdmin = this.jwtService.isSuperAdmin;
    this.isAdmin = this.jwtService.isAdmin;
    this.isCommercial = this.jwtService.isCommercial;
    this.isCustomer = this.jwtService.isCustomer;
    this.isUser = this.jwtService.isUser;
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  closeCallback(e: any): void {
    this.sidebarRef.close(e);
  }

  getAuthenticatedUserRoles(): string {
    if (this.jwtService.isValidAuthentication()) {
      return this.jwtService.getRoles();
    }
    return '';
  }

  getAuthenticatedUserUsername(): string {
    if (this.jwtService.isValidAuthentication()) {
      return this.jwtService.getUser();
    }
    return '';
  }

  logOut() {
    this.authenticationService.logout(this.jwtService.getToken())
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.jwtService.clear();
          this.router.navigate(['home']);
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Logout', detail: error.description, life: 5000 });
        }
      });
  }

  myProfile() {
    this.router.navigate(['portal/my-profile']);
  }

  showSidebarEvent() {
    this.sidebarVisible = true;
  }

  timerCheckJwtValidity() {
    let timeLeft = 10;
    this.interval = setInterval(() => {
      if (timeLeft > 0) {
        timeLeft--;
      } else {
        this.clearTimer();
        if (this.jwtService.isTokenExpired()) {
          this.jwtService.clear();
          this.router.navigate(['login'], { queryParams: { error: "The JWT token has expired" } });
        } else {
          this.timerCheckJwtValidity();
        }
      }
    }, 1000);
  }

  clearTimer(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  goToHomePage() {
    this.router.navigate(['home']);
  }

  changeTheme(theme: string) {
    localStorage.setItem("linkupp_theme", theme);
    this.theme = theme;
    this.themeService.switchTheme(theme);
  }
  //#endregion

  ngOnDestroy(): void {
    this.clearTimer();
    this._destroy$.next();
  }

}
