<!-- Main structure -->
<div class="block">
    <div>

        <!-- Super admin dash -->
        <div *ngIf="isSuperAdmin" class="mb-5">
            <div class="text-3xl font-medium text-900 mb-3 p-2">
                Dashboard super admin
            </div>
        </div>

        <!-- Admin dash -->
        <div *ngIf="isAdmin" class="mb-5">
            <div class="text-3xl font-medium text-900 mb-3 p-2">
                Dashboard admin
            </div>
            <!-- <div class="grid grid-nogutter h-full">
                <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                    <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                        <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                            Employés
                        </div>
                        <div class="border-2 border-dashed surface-border p-2">
                            <span class="font-medium text-6xl">{{ adminDashboard.employees }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                    <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                        <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                            Total des parrainages reçus
                        </div>
                        <div class="border-2 border-dashed surface-border p-2">
                            <span class="font-medium text-6xl">{{ adminDashboard.leads }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                    <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                        <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                            Clients
                        </div>
                        <div class="border-2 border-dashed surface-border p-2">
                            <span class="font-medium text-6xl">{{ adminDashboard.customers }}</span>
                        </div>
                    </div>
                </div>
            </div> -->
            <div>
                <div class="text-xl font-medium text-900 mb-1 p-2">
                    PARRAINAGES LINKUPP
                </div>
                <div class="grid grid-nogutter h-full">
                    <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                        <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                            <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                                Tous les parrainages
                            </div>
                            <div class="border-2 border-dashed surface-border p-2">
                                <span class="font-medium text-6xl">{{ adminDashboard.allLeads }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                        <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                            <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                                Parrainages en cours
                            </div>
                            <div class="border-2 border-dashed surface-border p-2">
                                <span class="font-medium text-6xl">{{ adminDashboard.runningLeads }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                        <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                            <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                                Parrainages validé(s)
                            </div>
                            <div class="border-2 border-dashed surface-border p-2">
                                <span class="font-medium text-6xl">{{ adminDashboard.completedLeads }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                        <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                            <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                                Parrainages annulé(s)
                            </div>
                            <div class="border-2 border-dashed surface-border p-2">
                                <span class="font-medium text-6xl">{{ adminDashboard.canceledLeads }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="text-xl font-medium text-900 mb-1 p-2">
                    RESSOURCES HUMAINES
                </div>
                <div class="grid grid-nogutter h-full">
                    <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                        <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                            <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                                Tous mes employés
                            </div>
                            <div class="border-2 border-dashed surface-border p-2">
                                <span class="font-medium text-6xl">{{ adminDashboard.allEmployees }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                        <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                            <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                                Tous mes clients
                            </div>
                            <div class="border-2 border-dashed surface-border p-2">
                                <span class="font-medium text-6xl">{{ adminDashboard.allCustomers }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                        <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                            <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                                Tous mes prospects
                            </div>
                            <div class="border-2 border-dashed surface-border p-2">
                                <span class="font-medium text-6xl">{{ adminDashboard.allProspects }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Commercial dash -->
        <div *ngIf="isCommercial" class="mb-5">
            <div class="text-3xl font-medium text-900 mb-3 p-2">
                Dashboard commercial
            </div>
            <div class="grid grid-nogutter ">
                <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                    <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                        <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                            Clients fidélisés
                        </div>
                        <div class="border-2 border-dashed surface-border p-2">
                            <span class="font-medium text-6xl">{{ commercialDashboard.customers }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                    <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                        <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                            Total des parrainages en cours
                        </div>
                        <div class="border-2 border-dashed surface-border p-2">
                            <span class="font-medium text-6xl">{{ commercialDashboard.runningLeads }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                    <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                        <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                            Total des parrainages validés
                        </div>
                        <div class="border-2 border-dashed surface-border p-2">
                            <span class="font-medium text-6xl">{{ commercialDashboard.completedLeads }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                    <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                        <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                            Total des parrainages annulés
                        </div>
                        <div class="border-2 border-dashed surface-border p-2">
                            <span class="font-medium text-6xl">{{ commercialDashboard.canceledLeads }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Customer dash -->
        <div *ngIf="isCustomer" class="mb-5">
            <div class="text-3xl font-medium text-900 mb-3 p-2">
                Dashboard client
            </div>
            <div class="grid grid-nogutter h-full">
                <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                    <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                        <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                            Entreprises affiliées
                        </div>
                        <div class="border-2 border-dashed surface-border p-2">
                            <span class="font-medium text-6xl">{{ customerDashboard.companies }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                    <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                        <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                            Total des parrainages envoyés
                        </div>
                        <div class="border-2 border-dashed surface-border p-2">
                            <span class="font-medium text-6xl">{{ customerDashboard.leadsAsGodFather }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-4 xl:col-3 p-2">
                    <div class="surface-card p-4 shadow-2 border-round h-full text-center align-content-end">
                        <div class="text-xl font-medium text-900 mb-3 overflow-hidden text-overflow-ellipsis">
                            Total des parrainages validés
                        </div>
                        <div class="border-2 border-dashed surface-border p-2">
                            <span class="font-medium text-6xl">{{ customerDashboard.completedLeadsAsGodFather }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Main structure -->