import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { UpdateProfileRequest } from '../../../payloads/requests/updateProfileRequest';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from '../../../services/loading.service';
// PrimeNG Modules
import { ButtonModule } from 'primeng/button';
import { StyleClassModule } from 'primeng/styleclass';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { FloatLabelModule } from 'primeng/floatlabel';

@Component({
  selector: 'app-my-profile',
  standalone: true,
  imports: [
    ButtonModule,
    StyleClassModule,
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    ToastModule,
    PasswordModule,
    DividerModule,
    FloatLabelModule
  ],
  providers: [
    MessageService
  ],
  templateUrl: './my-profile.component.html',
  styleUrl: './my-profile.component.sass'
})
export class MyProfileComponent implements OnInit, OnDestroy {

  //#region COMPONENT VARIABLES
  myProfile: any;

  passwordRegex: string = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+])[A-Za-z\\d!@#$%^&*()_+]{12,20}$';

  updateMyProfileForm = this.formBuilder.group({
    firstName: [null, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')],
    lastName: [null, Validators.pattern('^[a-zA-ZÀ-ÿ\\s-]{2,30}$')],
    email: [null, Validators.pattern('^(?=.{6,50}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$')],
    phone: [null, Validators.pattern('^[0-9]{10}$')],
    currentPassword: [null],
    newPassword: [null, Validators.pattern(this.passwordRegex)]
  }, { validators: [this.requireAtLeastOneFilledValidator()] });

  hideLastName: boolean = false;
  hideFirstName: boolean = false;
  hideEmail: boolean = false;
  hidePhone: boolean = false;
  hidePassword: boolean = false;

  private _destroy$ = new Subject<void>();
  //#endregion

  //#region COMPONENT INITIALISATION
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.userService.getMyProfile()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myProfile = response;
        }, error: error => {
          this.messageService.add({ severity: 'error', summary: 'Lister mon profile', detail: error.description, life: 5000 });
        }
      });
  }
  //#endregion

  //#region COMPONENT FUNCTIONS
  getLoading() {
    return this.loadingService.get();
  }

  onClickEnableFieldInput(field: string) {
    switch (field) {
      case 'lastName': {
        this.hideLastName = !this.hideLastName;
        this.updateMyProfileForm.patchValue({ lastName: null });
        break;
      }
      case 'firstName': {
        this.hideFirstName = !this.hideFirstName;
        this.updateMyProfileForm.patchValue({ firstName: null });
        break;
      }
      case 'email': {
        this.hideEmail = !this.hideEmail;
        this.updateMyProfileForm.patchValue({ email: null });
        break;
      }
      case 'phone': {
        this.hidePhone = !this.hidePhone;
        this.updateMyProfileForm.patchValue({ phone: null });
        break;
      }
      case 'password': {
        this.hidePassword = !this.hidePassword;
        this.updateMyProfileForm.patchValue({ currentPassword: null });
        this.updateMyProfileForm.patchValue({ newPassword: null });
        break;
      }
    }
  }

  onSubmitUpdatePrincipalProfile() {
    const request: UpdateProfileRequest = new UpdateProfileRequest(
      this.updateMyProfileForm.get('firstName')?.value!!,
      this.updateMyProfileForm.get('lastName')?.value!,
      this.updateMyProfileForm.get('email')?.value!,
      this.updateMyProfileForm.get('phone')?.value!,
      this.updateMyProfileForm.get('currentPassword')?.value!,
      this.updateMyProfileForm.get('newPassword')?.value!);

    this.userService.updateMyProfile(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: response => {
          this.myProfile = response;

          this.hideLastName = false;
          this.hideFirstName = false;
          this.hideEmail = false;
          this.hidePhone = false;
          this.hidePassword = false;
          this.updateMyProfileForm.reset();

          this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Profile mis à jour' });
        },
        error: (error: any) => {
          this.messageService.add({ severity: 'error', summary: 'Mise à jour du profile', detail: error.description, icon: 'pi pi-times-circle', life: 5000 });
        }
      });
  }

  requireAtLeastOneFilledValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasFilledControls = Object.values(control.value).some(
        (value: any) => value !== null && value !== undefined && value !== ''
      );

      return hasFilledControls ? null : { requireAtLeastOneFilled: true };
    };
  }

  pwdLowerCase() {
    const password = this.updateMyProfileForm.controls['newPassword'].value;
    const lower = /[a-z]/.test(String(password));
    if (password && lower) {
      return true;
    }
    return false;
  }

  pwdUpperCase() {
    const password = this.updateMyProfileForm.controls['newPassword'].value;
    const upper = /[A-Z]/.test(String(password));
    if (password && upper) {
      return true;
    }
    return false;
  }

  pwdDigit() {
    const password = this.updateMyProfileForm.controls['newPassword'].value;
    const digit = /[\d]/.test(String(password));
    if (password && digit) {
      return true;
    }
    return false;
  }

  pwdSpecialChar() {
    const password = this.updateMyProfileForm.controls['newPassword'].value;
    const special = /[!@#$%^&*()_+]/.test(String(password));
    if (password && special) {
      return true;
    }
    return false;
  }

  pwdLength() {
    const password = this.updateMyProfileForm.controls['newPassword'].value;
    const length = /^.{12,20}$/.test(String(password));
    if (password && length) {
      return true;
    }
    return false;
  }
  //#endregion

  ngOnDestroy(): void {
      this._destroy$.next();
  }

}
